import React, { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Flex, Heading, Wrap, WrapItem, Spinner, Text } from "@chakra-ui/react";
import SingleInvestor from "./SingleInvestor";
import IsDesktop from "../../utils/IsDesktop";
import SearchBar from "../searchbar/SearchBar";
import ViewInvestorDetails from "./ViewInvestorDetails";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";
import { useSearchParams } from "react-router-dom";

const InvestorsPage = () => {
    const [searchParams] = useSearchParams({});
    const userid = searchParams.get("userid");
    const {isOpen} = useDisclosure();
    const [selectedInvestor, setSelectedInvestor] = useState({});
    const isDesktop = IsDesktop();
    const {isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails} = useDisclosure();
    const [pageNo, setPageNo] = useState(1);
    const lastResultsCount = useRef(0);
    const [investors, setInvestors] = useState([]);
    const containerRef = useRef(null)

    const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight) => {
        let userScrollHeight = scrollpos;
        let windowBottomHeight = containerHeight;
        if (userScrollHeight >= windowBottomHeight) {
            if(lastResultsCount.current < 1){
                return;
            }else{
                setPageNo(pageNo + 1);
            }
        }
    };
    const updateState = (data) => {
        lastResultsCount.current = data.length
        setInvestors([...investors, ...data])
    }


    useEffect( ()=>{
        client().get(`${BASE_URL}user-types/investor?page_no=${pageNo}&page_size=10&filter=createdAt,updatedAt`)
            .then((res) => {
                const data = res.data.data.users;
                updateState(data)
        })
    },[pageNo])

    return (
        <Flex 
            ref={containerRef}
            onScroll={() => {handleScroll(containerRef.current.scrollTop + containerRef.current.clientHeight, containerRef.current.scrollHeight)}}
            className={`flex-col px-3 py-5 ${isDesktop ? 'max-h-[95vh] pb-10 overflow-y-scroll' : ''}`}
        >
            <div
             >
            {!isOpen && (
                <>
                 <>
                    <Flex justifyContent="space-between">
                        {isDesktop && <Box minW="100px" maxW="70%">
                            <Heading whiteSpace="nowrap" color="brand.primary" fontSize={isDesktop ? "20px" : "14px"}></Heading>
                            <Text color="gray" fontSize={16} >Connect with investors who not only provide capital but also bring industry-specific insights and connections aligned with your business goals. Whether you're seeking local support or expanding internationally, our network provides a global reach to propel your start-up's ambitions </Text>
                        </Box>}
                        <SearchBar userType={2} />
                    </Flex>
                    <Flex className="flex-col">
                    {investors.length > 0 ? (<Wrap h="100%" spacing={10} mt={5} py={2} px={2} justify={isDesktop ? "flex-start" : "center"}>
                        {investors.map((investor) =>(
                            <WrapItem key={investor.id}>
                                <SingleInvestor setSelectedInvestor={setSelectedInvestor} investor={investor} isOpen={viewDetailsIsOpen} onToggle={onToggleViewDetails}/>
                            </WrapItem>
                        ))}
                        </Wrap>

                    ): (
                        <Spinner/>
                        )}
                        </Flex>
                    </>
                </>
                        
                )}
            </div>
        </Flex>
    )

}
export default InvestorsPage;