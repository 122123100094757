import { useState, useEffect, useCallback } from "react";
import { Box, Flex, Input, InputLeftElement, InputGroup, Stack, Text, Icon, useDisclosure, TabPanels, Tabs, Tab, TabPanel, TabList, Avatar, Spinner, Center } from "@chakra-ui/react";
import { SearchIcon, StarIcon } from "@chakra-ui/icons";
import Header from "../../../components/header/Header";
// import { messages } from "./messages";
import { HiChat } from "react-icons/hi";
import IsDesktop from "../../../utils/IsDesktop";
import StyledSpinner from "../../../components/spinner/Spinner";
import { useData } from "../../../data";
import ChatBox from "./ChatBox";
import ConnectionMessage from "./ConnectionMessage";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useInterval } from "../../../utils/useInterval";
import SearchBar from "../../../components/searchbar/SearchBar";
import { Select } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { BASE_URL } from "../../../helpers/constant";
import { setAllUserMessages } from "../../../redux-toolkit/reducers/messagesSlice";
import client from "../../../libs/client";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AdvertMessage from "./AdvertMessage";

const Connections = () => {
  // const socket = io.connect(`ws://hub8020-crowdfunding-backend.herokuapp.com`)
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const isDesktop = IsDesktop();
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id || null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const messages = useSelector((state) => state?.usermessages?.messages)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [isAdvert, setIsAdvert] = useState(false)
  const [advertId, setAdvertId] = useState(null)
  const [selectedAdvert, setSelectedAdvert] = useState(null)
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;
  const navigate = useNavigate()


  // const { data, isLoading, refetch } = useData(`messages`);
  const [connections, setConnections] = useState([]);
  const { data: connectionsData, isLoading: connectionsLoading, refetch: refetchConnections } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=100`, 30000);
  const { data: inboxAdverts, isLoading: inboxAdvertsLoading, refetch: refetchInboxAdverts } = useData(`inbox-adverts/`);
  const { isOpen: chatBoxIsOpen, onOpen: onOpenChatBox, onClose: onCloseChatBox, onToggle: onToggleChatBox } = useDisclosure();


  useEffect(() => {
    if(Object.keys(messages).length < 1){
      client().get(`${BASE_URL}messages`)
        .then((res) => {
          dispatch(setAllUserMessages(res?.data?.data?.messages))
        })
    }
  }, [])
  
  useEffect(() => {
    const _id = location.search.slice(8)
    if(!_id){
      return 
    }
    const _theConnection = connections?.find((connection) => connection?.id?.toString() === _id)
    if(_theConnection){
      setRecipientId(_id)
      setRecipient(_theConnection);
      onOpenChatBox();
    }

  }, [location?.search, connections?.length])

  const sortFn = (a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at || b?.createdAt).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at || a?.createdAt), 'second')
  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(connectionsData?.data?.connections?.sort(sortFn));
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  const openChatBox = (recipientId, recipient) => {
    setIsAdvert(false)
    setAdvertId(null)
    setSelectedAdvert(null)
    setRecipientId(recipientId);
    const _theConnection = connections?.find((connection) => connection?.id?.toString() === recipientId)
    setRecipient(_theConnection || recipient);
    onOpenChatBox();
  };
  const openAdvert = (advertId, advert) => {
    setIsAdvert(true)
    setAdvertId(advertId)
    setSelectedAdvert(advert)
    setRecipient(null)
    setRecipientId(null)
    onOpenChatBox()
  }
  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    setAdvertId(null)
    setSelectedAdvert(null)
    onToggleChatBox();
  };
  const isFromNavigate = () => {
    return chatWithUserState && chatWithUserState?.id === recipientId && chatWithUserState?.connection?.accepted;
  };

  

  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoading(true);
      if (inputValue !== "") {
        setSearchData(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes(inputValue.toLowerCase())));
      }
      setIsLoading(false);
    },
    [connections]
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar size="sm" name={`${user.first_name} ${user.last_name}`} src={user.profile_image} />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(connections?.find((connection) => connection?.id?.toString() === userId.toString()));
    onOpenChatBox();
  };

  // console.log(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes("Rachel".toLowerCase())))

  return (
    <>
      <Box h="100vh">
        <Header title="Connections" />
        <Flex className={`flex-col ${isDesktop ? 'h-full max-h-[87vh]' : ''}`}>
        {!chatBoxIsOpen && <Box mt={6} pl={8}>
          <Text color="brand.primary" fontWeight={700} fontSize={"20px"}>
            Chat with your Connections
          </Text>
        </Box>}
        {isDesktop ? (
          <Flex flexDir={isDesktop ? "row" : "column"} spacing="24px" p={8}>
            <Box className={`${isDesktop ? 'w-[50%] xl:w-[30%]' : 'w-full'}`} h="100%">
              <Tabs minW="100%" rounded="10px" mr="20px" mt="0px" p="0" variant="unstyled">
                <TabList h="100%">
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    Recent
                  </Tab>
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    All
                  </Tab>
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    New Requests
                  </Tab>
                </TabList>
                <InputGroup mx={4} my={4} w="95%" border="1px" borderColor="brand.primary" borderRadius="5px">
                  <InputLeftElement pointerEvents="none" children={<SearchIcon color="#134A70" />} mr={0} />
                  <Select
                    key="globalSelect"
                    size="large"
                    showSearch
                    labelInValue={false}
                    autoClearSearchValue
                    allowClear
                    listHeight={500}
                    placeholder="Search"
                    style={{ width: "100%", maxHeight: "75vh", marginLeft: "30px" }}
                    defaultActiveFirstOption={false}
                    bordered={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={searchConnections}
                    onChange={setSearchInput}
                    notFoundContent={isLoading ? <Spinner /> : "No data to show"}
                    onSelect={openSearchedUserChatBox}
                  >
                    {!isLoading && (
                      <>
                        <OptGroup key="searchConnections" label="Users">
                          {searchResults.length > 0 ? (
                            searchResults
                          ) : (
                            <Select.Option key="searchDatanull" fontSize="3px">
                              No match found
                            </Select.Option>
                          )}
                        </OptGroup>
                      </>
                    )}
                  </Select>
                </InputGroup>
                <TabPanels mt={-4}>
                  <TabPanel p={0} m={0}>
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!chatBoxIsOpen ? "65vh" : "70vh"} className="">
                      {connectionsLoading ? <StyledSpinner /> : <>{connections?.length > 0 ? connections.filter((b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at).isAfter(moment().subtract(1, 'week')))?.map((connection, index) => <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                    </Box>
                  </TabPanel>
                  <TabPanel p={0} m={0}>
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!chatBoxIsOpen ? "65vh" : "70vh"} className="">
                        {connectionsLoading ? <StyledSpinner /> : <>{[...(inboxAdverts?.data?.adverts || []), ...connections]?.length > 0 ? [...(inboxAdverts?.data?.adverts || []), ...connections]?.sort(sortFn)?.map((connection, index) =>  connection?.advert_type ? <AdvertMessage advert={connection} key={index} openChatBox={openAdvert} /> : <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                    </Box>
                  </TabPanel>
                  <TabPanel p={0} m={0}>
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!chatBoxIsOpen ? "65vh" : "70vh"} className="">
                      {connectionsLoading ? <StyledSpinner /> : <>{connections?.length > 0 ? connections.filter((b) => b.accepted === false)?.map((connection, index) => <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            <Box className={`${isDesktop ? 'w-[50%]' : 'w-full'}`} h="100%">
              {!chatBoxIsOpen && (
                <Flex align="center" justify="center" flexDirection="column" mt={52}>
                  <Icon as={HiChat} w={12} h={12} color="gray.500" mb={4} />
                  <Text fontSize={16} fontWeight={800} color="#9FA7AD" mb={4}>
                    It's nice to chat with your connections
                  </Text>
                  <Text fontSize={12} fontWeight={400} color="#9FA7AD" w="50%" align="center">
                    Pick a connection from the left menu and start a conversation
                  </Text>
                </Flex>
              )}
              {chatBoxIsOpen && <ChatBox advert={selectedAdvert} advertId={advertId} isAdvert={isAdvert} setConnections={setConnections} isFromNavigate={isFromNavigate} recipientId={recipientId} setRecipient={setRecipient} recipient={recipient} closeChatBox={closeChatBox} refetchConnections={refetchConnections} />}
            </Box>
            {chatBoxIsOpen && (
                <Box className="py-6 w-[20%] hidden xl:flex items-center">
                  {
                    !isAdvert ? (
                      <Flex className="flex-col w-full border-b border-t py-5">
                        <Avatar onClick={() => {navigate(`/view-user?userid=${recipient?.id}&usertype=${recipient?.user_type_id}`)}} cursor={"pointer"} name={`${recipient?.first_name} ${recipient?.last_name}`} className="mb-2" size="sm" src={recipient?.profile_image}/>
                        <Text className="font-semibold mb-2">
                          About <span onClick={() => {navigate(`/view-user?userid=${recipient?.id}&usertype=${recipient?.user_type_id}`)}} className="underline cursor-pointer font-semibold capitalize">{recipient?.first_name}</span>
                        </Text>
                        <Text className="text-sm text-gray-800 mb-2">
                          Joined on:  <span className="text-black capitalize">{moment(recipient?.created_at).format('YYYY')}</span>
                        </Text>
                        <Text className="text-sm text-gray-800 mb-2">
                          Type:  <span className="capitalize text-black">{recipient?.userTypeTitle}</span>
                        </Text>
                        {
                          recipient?.user_type_id == 1 && (
                            <Flex className="flex-row gap-1 items-center mb-2">
                              <Text className="text-sm mr-1 text-gray-800">
                                Rating:
                              </Text>
                              <StarIcon h={3} w={3} color={recipient?.rating > 0 ? "yellow.400" : "initial"} />
                              <StarIcon h={3} w={3} color={recipient?.rating > 1 ? "yellow.400" : "initial"} />
                              <StarIcon h={3} w={3} color={recipient?.rating > 2 ? "yellow.400" : "initial"} />
                            </Flex>
                          )
                        }
                    </Flex>
                    ) : (
                      <Flex className="flex-col w-full border-b border-t py-5">
                        <Avatar name={selectedAdvert?.brand?.name} className="mb-2" size="sm" src={selectedAdvert?.brand?.logo}/>
                        <Text className="font-semibold mb-2">
                          About <a target={"_blank"} href={selectedAdvert?.external_link || "#"} className="underline font-semibold ">{selectedAdvert?.brand?.name}</a>
                        </Text>
                      </Flex>
                    )
                  }
                </Box>
              )}
          </Flex>
        ) : (
          <Flex px={4} h="80vh">
            {!chatBoxIsOpen && (
              <Box w={"100%"} h="100%">
                <Tabs minW="100%" rounded="10px" mr="20px" mt="0px" p="0" variant="unstyled">
                  <TabList h="100%">
                    <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                      Recent
                    </Tab>
                    <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                      All
                    </Tab>
                    <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                      New Requests
                    </Tab>
                  </TabList>
                  <InputGroup mx={4} my={4} w="95%" border="1px" borderColor="brand.primary" borderRadius="5px">
                    <InputLeftElement pointerEvents="none" children={<SearchIcon color="#134A70" />} mr={0} />
                    <Select key="globalSelect" size="large" showSearch labelInValue={false} autoClearSearchValue allowClear listHeight={500} placeholder="Search" style={{ width: "100%", maxHeight: "75vh", marginLeft: "30px" }} defaultActiveFirstOption={false} bordered={false} showArrow={false} filterOption={false} onSearch={searchConnections} onChange={setSearchInput} notFoundContent={isLoading ? <Spinner /> : "No data to show"} onSelect={openSearchedUserChatBox} >
                      {!isLoading && (
                        <>
                          <OptGroup key="searchConnections" label="Users">
                            {searchResults.length > 0 ? (
                              searchResults
                            ) : (
                              <Select.Option key="searchDatanull" fontSize="3px">
                                No match found
                              </Select.Option>
                            )}
                          </OptGroup>
                        </>
                      )}
                    </Select>
                  </InputGroup>
                  <TabPanels mt={-4}>
                    <TabPanel p={0} m={0} h="750px" overflowY="auto">
                      <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "95%" : "502px"}>
                      {connectionsLoading ? <StyledSpinner /> : <>{connections?.length > 0 ? connections.filter((b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at).isAfter(moment().subtract(1, 'week')))?.map((connection, index) => <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                      </Box>
                    </TabPanel>
                    <TabPanel p={0} m={0} h="750px" overflowY="auto">
                      <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "95%" : "502px"}>
                        {connectionsLoading ? <StyledSpinner /> : <>{[...(inboxAdverts?.data?.adverts || []), ...connections]?.length > 0 ? [...(inboxAdverts?.data?.adverts || []), ...connections]?.map((connection, index) =>  connection?.advert_type ? <AdvertMessage advert={connection} key={index} openChatBox={openAdvert} /> : <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                      </Box>
                    </TabPanel>
                    <TabPanel p={0} m={0} h="750px" overflowY="auto">
                      <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "95%" : "502px"}>
                      {connectionsLoading ? <StyledSpinner /> : <>{connections?.length > 0 ? connections.filter((b) => b.accepted === false)?.map((connection, index) => <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <>No connections...</>}</>}
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}

            {chatBoxIsOpen && (
              <Box w={"100%"} h="80vh">
                <Box display="flex" alignItems="center" cursor="pointer" onClick={() => onCloseChatBox()}>
                  <BsArrowLeft size={20}/>
                  <Text ml="0.625rem" fontSize="1xl" fontWeight="700" color="#134A70">
                    Go Back
                  </Text>
                </Box>
                <ChatBox advert={selectedAdvert} advertId={advertId} isAdvert={isAdvert} setConnections={setConnections} isFromNavigate={isFromNavigate} recipientId={recipientId} setRecipient={setRecipient} recipient={recipient} closeChatBox={closeChatBox} refetchConnections={refetchConnections} />
              </Box>
            )}
          </Flex>
        )}
        </Flex>
      </Box>
    </>
  );
};

export default Connections;
