import {Flex, Box } from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import InvestorsPage from "../../../components/investors/InvestorsPage";
import IsDesktop from "../../../utils/IsDesktop";


const Investors = () => {
    const isDesktop = IsDesktop()
    return (
        <Box >
            <Header title='Investors List'  />
            <Flex>
                <InvestorsPage/>
            </Flex>
        </Box>
    );
}
 
export default Investors;