import React, { useState, useEffect, useRef } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Flex, Heading, Wrap, WrapItem, Spinner, Text } from "@chakra-ui/react";
import SingleExpert from "./SingleExpert";
import IsDesktop from "../../utils/IsDesktop";
import SearchBar from "../searchbar/SearchBar";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import ViewExpertDetails from "./ViewExpertDetails";
import { useData } from "../../data";
import Filter from "../filter/Filter";

const ExpertsPage = () => {
    const {isOpen} = useDisclosure();
    const [selectedExpert, setSelectedExpert] = useState({});
    const isDesktop = IsDesktop();
    const [pageNo, setPageNo] = useState(1);
    const lastResultsCount = useRef(0);
    const [experts, setExperts] = useState([]);
    const {isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails} = useDisclosure();
    const [loading, setLoading] = useState(false)
    const [filter, setFilters] = useState({})
    const {data : _expertCategoriesData} = useData("experts/categories")
    const {data : _expertLocations} = useData("experts/locations")
    const categoriesData = _expertCategoriesData?.data || null
    const locationsData = _expertLocations?.data || null


    const updateFilter = (data) => {
        setFilters(data);
    };

    const handleScroll = () => {
        let userScrollHeight = window.innerHeight + window.scrollY;
        let windowBottomHeight = document.documentElement.offsetHeight;

        if (userScrollHeight >= windowBottomHeight) {
            if(lastResultsCount.current < 1){
                return;
            }else{
                let _pageNo = pageNo + 1;
                setPageNo(_pageNo);
                getExperts({ pageNumber: _pageNo });
            }
        }
    };
    const updateState = (data, append = true, isFetchedFromServer = true) => {
        if(isFetchedFromServer)lastResultsCount.current = data.length;
        let _experts = append ? [...experts, ...data] : [...data];
        setExperts(_experts);
        setLoading(false);
    };

    const parseFilters = (obj) => {
        const params = {
          categories: "category",
          locations: "location"
        };
        return Object.entries(obj)
          .map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`)
          .join("&");
    };

    const getExperts = ({ filters = filter, append = true, pageNumber = 1 }) => {
        const _filters = parseFilters(filters);
        setLoading(true)
        return client().get(`${BASE_URL}experts?page_no=${pageNumber}&page_size=10${
            _filters ? `&${_filters}` : ""
        }`).then((res) => {
                const data = res.data.data.experts;
                updateState(data, append)
        }).catch(err => {
            setLoading(false)
        })
    };

    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
        getExperts({})

        return () => { window.removeEventListener("scroll", handleScroll)}
    },[])


    return (
        <>
            <Box mt={10} mx={5} py={2}>
            {/* <Text>{experts}</Text> */}
            {!isOpen && (<>
                {viewDetailsIsOpen ?(<ViewExpertDetails expert={selectedExpert.id} onToggle={onToggleViewDetails}/>):
                (<>
                    <Flex className="flex-col gap-5" justifyContent="space-between">
                        
                        <Flex className="flex-row w-full justify-between items-center flex-wrap md:flex-nowrap  gap-4">
                            <Heading whiteSpace="nowrap" color="brand.primary" fontSize={"20px"}>Book Sessions with Experts</Heading>
                            <Flex className="flex-row max-w-min items-center gap-2">
                                <Filter
                                    onChange={updateFilter}
                                    onApply={(data) => {
                                      getExperts({ filter: data, append: false });
                                    }}
                                    filters={{...categoriesData, ...locationsData}}
                                    multichoice={true}
                                />
                                <SearchBar userType={3} />
                            </Flex>
                        </Flex>
                        {isDesktop && 
                        <Box minW="100px" maxW="90%">
                            <Text color="gray" fontSize={16}>Are you looking for expert services to help grow your startup? Look no further! Below is a list of experts offering a range of professional services to help startups succeed, including legal, financial, marketing, and development services. Whether you need help with legal documents, financial planning, digital marketing, or website development, our platform has a variety of experts who can provide tailored advice and support for your business. When you find an expert that interests you, simply contact them directly to book a session and discuss your specific needs. Our experts have years of experience working with startups, and they're passionate about helping emerging businesses grow and thrive. Join our community today and connect with the best expert services for your startup!</Text>
                        </Box>}
                    </Flex>
                    {loading? <Spinner/> : <>{experts.length > 0 ? (<Wrap h="100%" spacing={10} mt={5} py={2} px={2} justify={isDesktop ? "flex-start" : "center"}>
                        {experts.map((expert) =>(
                            <WrapItem key={expert.id}>
                                <SingleExpert setSelectedExpert={setSelectedExpert} expert={expert} isOpen={viewDetailsIsOpen} onToggle={onToggleViewDetails}/>
                            </WrapItem>
                            // <p>{expert.id}</p>
                        ))}
                        </Wrap>
                    ): (
                       <div style={{marginTop:"20px", display:'flex', justifyContent:"center"}}> No expert available yet.</div>
                    )}</>}
                    
                </>)}
                </>)}
                

            </Box>
        </>
    )

}
export default ExpertsPage;