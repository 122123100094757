import { Flex, Box, Text, Spacer } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import DashboardAdvertCard from "./DashboardAdvertCard";
import getRandomInt from "../../../utils/getRandomInt";
import { useEffect } from "react";

const dataImg = [
  { id: 1, img: "/assets/images/1.png" },
  { id: 3, img: "/assets/images/3.png" },
  { id: 4, img: "/assets/images/4.png" },
  { id: 5, img: "/assets/images/5.png" },
];

const AdvertFeature = () => {
  const {data} = useData("adverts")
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const [firstRandomNumber, setFirstRandomNumber] = useState(getRandomInt(0, (data?.data?.adverts || []).length))

  const [secondRandomNumber, setSecondRandomNumber] = useState(0)


  useEffect(() => {
    let _secondRandomNumber = (() => {
      let _num = getRandomInt(0, (data?.data?.adverts || []).length)
      return _num !== firstRandomNumber ? _num : firstRandomNumber > 0 ? _num - 1 : _num + 1
    })()

    setSecondRandomNumber(_secondRandomNumber)
  }, [firstRandomNumber])



  return (
    <Box borderWidth="1px" borderRadius="lg"  px={3}  w="full" minW="300px">
      <Flex mt={3} mb={4}>
        <Box>
          <Text color="#134A70" fontSize="18px" fontWeight="700">
            Featured Adverts
          </Text>
        </Box>
        <Spacer />
        <Box>
          <Text justifyItems="left" fontSize="1xl" fontWeight="500" textDecorationLine="underline" color="#134A70" cursor="pointer" onClick={() => navigate("/advert")}>
            See All
          </Text>
        </Box>
      </Flex>
      {
        data?.data?.adverts?.length > 0 ? (
          <Flex direction={"column"} w="100%" h="100%">
            {[data?.data?.adverts[firstRandomNumber], data?.data?.adverts[secondRandomNumber]] ?.map((advert) => advert ? (<DashboardAdvertCard advert={advert}/>) : null)}
          </Flex>
        ) : (
          <Flex direction={"column"} w="100%" h="100%">
            <Text className="text-center w-full pt-7 text-gray-500 pb-14">
              No adverts at this moment
            </Text>
          </Flex>
        )
      }
      
    </Box>
  );
};

export default AdvertFeature;
