import { Avatar, Box, HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import IsDesktop from "../../../utils/IsDesktop";
import { useSelector } from "react-redux";

const AdvertMessage = ({advert, openChatBox}) => {
  const isDesktop = IsDesktop();

  const getLastTimeStamp = () => {
   return moment(advert?.createdAt).format("MMM DD")
  };

  return (
    <>
      <Box
        onClick={() => openChatBox(advert?.id, advert)}
        borderRadius={4}
        //   bg={index === recipient ? "#F5F5F5" : "#fff"}
        bg="#white"
        _hover={{backgroundColor:"#F5F5F5"}}
        borderTop="1px solid rgba(19, 74, 112, 0.2)"
        cursor="pointer"
      >
        <HStack p={3} justifyContent="space-between">
          <Avatar name={advert?.brand?.name} src={advert?.brand?.logo} alt="Avatar" w="48px" h="48px" borderRadius="100%" />
          <Box className="max-w-[60%]" p={2} pl={4} flex={1}>
            <Text fontSize={15} fontWeight={700}>
              {advert?.brand?.name}
            </Text>
            <Text className="max-w-[250px]" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={12} fontWeight={600} color="#062F36" pr={2}>
              <Text className="text-gray-500 font-semibold inline-block">Sponsored&nbsp;&nbsp;</Text>{advert?.title}
            </Text>
          </Box>
          <Box p={2}>
            <Text fontSize={10} color="#134A70" className="min-w-[56px]" fontWeight={600} mb={10}>
              {getLastTimeStamp()}
            </Text>
          </Box>
        </HStack>
      </Box>
      
    </>
  );
};
export default AdvertMessage;
