import { 
    Box, 
    Flex, 
    HStack, 
    VStack, 
    Tabs, 
    TabList, 
    TabPanels,
    Tab, 
    TabPanel,
    Heading,
    useDisclosure,
    Avatar,
    Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import Header from "../../../components/header/Header";
import AdvertFeature from "./AdvertFeature";
import ConnectCard from "./ConnectWithUserCards/ConnectCard";
import {useData} from "../../../data"
import StartupOffersCard from "./StartupOffersCard";
import IsDesktop from "../../../utils/IsDesktop";
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { SearchIcon } from "@chakra-ui/icons";
// import { messages } from "./messages";
import { HiChat } from "react-icons/hi";
import StyledSpinner from "../../../components/spinner/Spinner";
import ChatBox from "../../experts/Connections/ChatBox";
import ConnectionMessage from "../../experts/Connections/ConnectionMessage"
import AdvertMessage from "../../experts/Connections/AdvertMessage";
import { useSelector } from "react-redux";
import moment from "moment";
import client from "../../../libs/client";
import { useInterval } from "../../../utils/useInterval";
import SearchBar from "../../../components/searchbar/SearchBar";
import { Select } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import ConnectionButton from "../../../components/connectionButton/ConnectionButton";
import { useRef } from "react";
import { BASE_URL } from "../../../helpers/constant";


const Dashboard = () => {
    //const {data:experts, isLoading : isEXPLoading} = useData(`user-types/expert?page_no=1&page_size=5&filter=createdAt,updatedAt`)
    //const {data: investors, isLoading: isINVLoading} = useData(`user-types/investor?page_no=1&page_size=5&filter=createdAt,updatedAt`)
    const [experts, setExperts] = useState([])
    const [investors, setInvestors] = useState([])
    const [isEXPLoading, setIsEXPLoading] = useState(false)
    const [isINVLoading, setIsINVLoading] = useState(false)
    const [investorPageNo, setInvestorPageNo] = useState(1);
    const [expertsPageNo, setExpertsPageNo] = useState(1);
    const sliceBy = 4
    const [ investorSliceCount, setInvestorSliceCount ] = useState(0)
    const [ expertsSliceCount, setExpertSliceCount ] = useState(0)
    const expertsLastResultsCount = useRef(0);
    const investorsLastResultsCount = useRef(0);
    const [tabIndex, setTabIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(true)
    const loggedInUser = useSelector((state) => state?.user?.userInfo);
    const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
    const isDesktop = IsDesktop();
    const [recipient, setRecipient] = useState(chatWithUserState);
    const [recipientId, setRecipientId] = useState(chatWithUserState?.id || null);
    const [isAdvert, setIsAdvert] = useState(false)
    const [advertId, setAdvertId] = useState(null)
    const [selectedAdvert, setSelectedAdvert] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [showMessageTray, setShowMessageTray] = useState(false)
    const [searchInput, setSearchInput] = useState("");
    const [searchData, setSearchData] = useState([]);
    const { OptGroup } = Select;
    const [selectedUserIndex, setSelectedUserIndex] = useState(0)
    const [selectedUser, setSelectedUser] = useState(null)
    const expertsContainerRef = useRef(null)
    const investorsContainerRef = useRef(null)

    // const { data, isLoading, refetch } = useData(`messages`);
    const [connections, setConnections] = useState([]);
    const { data: connectionsData, isLoading: connectionsLoading, refetch: refetchConnections } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=100`, 30000);
    const { isOpen: chatBoxIsOpen, onOpen: onOpenChatBox, onClose: onCloseChatBox, onToggle: onToggleChatBox } = useDisclosure();
    const { data: inboxAdverts, isLoading: inboxAdvertsLoading, refetch: refetchInboxAdverts } = useData(`inbox-adverts/`);
    const sortFn = (a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at || b?.createdAt).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at || a?.createdAt), 'second')    
    
    useEffect(() => {
        if (connectionsData?.data) {
        setConnections(connectionsData?.data?.connections?.sort(sortFn));
        }
        if (chatWithUserState) {
        openChatBox(chatWithUserState?.id, chatWithUserState);
        }
    }, [connectionsData?.data?.connections, chatWithUserState]);
    
    const onSelectUser = (id) => {
        setSelectedUserIndex(id)
    }

    useEffect(() => {
        onSelectUser(0)
    }, [tabIndex])

    useEffect(() => {
        let user = !tabIndex ? investors[selectedUserIndex] : experts[selectedUserIndex]
        user && setSelectedUser(user)
    }, [tabIndex, selectedUserIndex, isINVLoading, isEXPLoading, investors.length, experts.length])

    const openChatBox = (recipientId, recipient) => {
        setIsAdvert(false)
        setAdvertId(null)
        setSelectedAdvert(null)
        setRecipientId(recipientId);
        setRecipient(recipient);
        onOpenChatBox();
    };

    const openAdvert = (advertId, advert) => {
        // console.log("opening advert=====", advertId, advert, recipient, recipientId)
        setIsAdvert(true)
        setAdvertId(advertId)
        setSelectedAdvert(advert)
        setRecipient(null)
        setRecipientId(null)
        onOpenChatBox()
    }
    const closeChatBox = () => {
        setRecipientId(null);
        setRecipient(null);
        setAdvertId(null)
        setSelectedAdvert(null)
        onToggleChatBox();
    };
    const isFromNavigate = () => {
        return chatWithUserState && chatWithUserState?.id === recipientId && chatWithUserState?.connection?.accepted;
    };
    useInterval(() => {
        refetchConnections();
    }, 15000);

    const searchConnections = useCallback(
        (inputValue) => {
        setIsLoading(true);
        if (inputValue !== "") {
            setSearchData(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes(inputValue.toLowerCase())));
        }
        setIsLoading(false);
        },
        [connections]
    );

    const openMessageTray = () => {
        if(loggedInUser?.status == "disabled") return 
        setShowMessageTray(prev => !prev)
    }

    const searchResults = searchData?.map((user) => (
        <Select.Option key={user.id}>
        <Flex alignItems="center">
            <Avatar size="sm" name={`${user.first_name} ${user.last_name}`} src={user.profile_image} />
            <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
        </Flex>
        </Select.Option>
    ));

    const openSearchedUserChatBox = (userId) => {
        setRecipientId(userId);
        setRecipient(connections?.find((connection) => connection?.id?.toString() === userId.toString()));
        onOpenChatBox();
    };


    const btnRef = React.useRef()

    const handleTabsChange = (index) => {
        setTabIndex(index)
    }


    const updateInvestorState = (data, append = true) => {
        investorsLastResultsCount.current = data.length;
        let _investors = append ? [...investors, ...data] : [...data]
        setInvestors(_investors)
        if(!append) setSelectedUserIndex(0)
        setIsINVLoading(false)
    }

    const updateExpertState = (data, append = true) => {
        expertsLastResultsCount.current = data.length;
        let _experts = append ? [...experts, ...data] : [...data]
        setExperts(_experts);
        if(!append) setSelectedUserIndex(0)
        setIsEXPLoading(false)
    }

    const getInvestors = ({append = true, pageNumber = 1 }) => {
        return client().get(`${BASE_URL}user-types/investor?page_no=${pageNumber}&page_size=10&filter=createdAt,updatedAt`)
            .then((res) => {
                setIsINVLoading(true)
                const data = res.data.data.users;
                updateInvestorState(data, append)
        })
    }

    const getExperts = ({append = true, pageNumber = 1 }) => {
        return client().get(`${BASE_URL}experts?page_no=${pageNumber}&page_size=10&filter=createdAt,updatedAt`)
            .then((res) => {
                setIsEXPLoading(true)
                const data = res.data.data.experts;
                updateExpertState(data, append)
        })
    }


    const loadMore = (isInvestors) => {
        let lastResultsCount = !isInvestors ? investorsLastResultsCount : expertsLastResultsCount
        if(isInvestors && investorSliceCount > 0){
            setInvestorSliceCount(prev => prev - 1)
            return
        }
        if(!isInvestors && expertsSliceCount > 0){
            setExpertSliceCount(prev => prev - 1)
            return
        }
        if (lastResultsCount.current < 1) {
            return;
        } else {
            let _pageNo = (isInvestors ? investorPageNo : expertsPageNo) + 1
            isInvestors ? setInvestorPageNo(_pageNo) : setExpertsPageNo(_pageNo);
            isInvestors ? getInvestors({pageNumber: _pageNo}) : getExperts({pageNumber: _pageNo})
        }
    }

    const loadLess = (isInvestors) => {
        if(isInvestors && (investorSliceCount * sliceBy) + sliceBy * 2 < investors.length){
            let _scrollHeight = investorsContainerRef.current.scrollHeight -  investorsContainerRef.current.clientHeight - 240
            _scrollHeight = _scrollHeight < 0 ? 0 : _scrollHeight
            investorsContainerRef.current.scrollTo({
                top : _scrollHeight,
            })
 
            setTimeout(() => {
                setInvestorSliceCount(prev => prev + 1)
            }, 100)
        }
        if(!isInvestors && ( expertsSliceCount * sliceBy) + sliceBy * 2 < experts.length){
            let height = expertsContainerRef.current.scrollTop + expertsContainerRef.current.clientHeight - 240
            expertsContainerRef.current.scrollTop = height  
            setTimeout(() => { 
                setExpertSliceCount(prev => prev + 1)
            }, 100)
        }
    }


    const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight, isInvestors = true) => {
        let userScrollHeight = scrollpos;
        let windowBottomHeight = containerHeight;
        if (userScrollHeight >= windowBottomHeight) {
            loadMore(isInvestors)
        }
    };


    const onClickUserConnectionButton = (id, status) => {
        const _list = (tabIndex == 0 ? [...investors] : [...experts]).map((item) => {
          if(item.id === id) {
            item.connectionStatus = status;
          }
          if(item.id === id && status === "Connected" ){
            item.connection.accepted ? item.connection.accepted = true : item.accepted = true;
          }
          return item
        })

        tabIndex == 0 ? setInvestors(_list) : setExperts(_list)
    }

    const PageHeader = ({hide = false}) => {
        return (
            <Box className={`w-[100%] ${hide ? 'hidden' : ''}`}>
                <Box className="text-gray-500 font-semibold mb-4">
                        <Text>
                            {!tabIndex ? `Connect with investors offering capital, industry insights, and connections tailored to your business goals.` : 'Connect with experts offering industry insights, guidance and specific knowledge to enable your business scale'} 
                        </Text>
                    </Box>
                    {isDesktop && (tabIndex ? experts.length > 0 : investors.length > 0) && (
                    <Flex className="bg-gray-50 p-5 lg:p-10 mb-6 rounded-sm" w={"full"} flexDirection={"column"}>
                        <Flex className="items-center pb-5 gap-3">
                            <HStack alignItems={"flex-start"} gap={1} className="w-full">
                                <Box py={2}>
                                    <Avatar borderRadius="100%" size={"lg"} name={`${selectedUser?.first_name || ""} ${selectedUser?.last_name || ""}`} src={selectedUser?.profile_image}/>
                                </Box>
                                <VStack alignItems={"flex-start"} pt={5}>
                                    <Heading className="flex whitespace-nowrap" fontSize="1.5rem">
                                    {`${(selectedUser?.first_name + " " + selectedUser?.last_name).slice(0, 24)}${
                                        (selectedUser?.first_name + " " + selectedUser?.last_name).length > 24
                                        ? "..."
                                        : ""
                                    }`}
                                    </Heading>
                                </VStack>
                            </HStack>
                            <Box>
                                <ConnectionButton onCompleteAction={onClickUserConnectionButton} user={selectedUser} />
                            </Box>
                        </Flex>
                            
                            <Flex flexDirection={"column"} gap={2.5} className="mb-4">
                                {
                                    tabIndex ? (
                                        <>
                                            <Text><Text className="text-gray-600 inline font-[500]">Company name:</Text> {`${selectedUser?.expert_price?.company_name ||""}`}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Profession:</Text> {selectedUser?.position || ""}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Location:</Text> {selectedUser?.expert_price?.location?.title || ""}</Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text><Text className="text-gray-600 inline font-[500]">Company Name:</Text> {selectedUser?.company?.title}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Company Stage:</Text> {selectedUser?.company?.company_stage.map(({title}) => title).join(', ')}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Kind of fund:</Text> {selectedUser?.company?.fund_category?.title || ""}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Location of Company:</Text> {selectedUser?.company?.incorporation_location.map(({name}) => name).join(', ')}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Business Model:</Text> {selectedUser?.company?.business_model.map(({title}) => title).join(', ')}</Text>
                                            <Text><Text className="text-gray-600 inline font-[500]">Category:</Text> {selectedUser?.company?.business_category.map(({title}) => title).join(', ')}</Text>
                                            
                                        </>
                                    )
                                }
                                
                            </Flex>
                            <Flex flexDirection={"column"} gap={4}>
                                <Text className="text-gray-600 font-[500]">{!tabIndex ? "Description" : "About Me"}</Text>
                                <Text>{!tabIndex ?  selectedUser?.company?.description : !selectedUser?.bio || selectedUser?.bio === 'Lorem Ipsum para para parap' ? '' : selectedUser?.bio}</Text>
                            </Flex>
                    </Flex>)}
            </Box>
        )
    }

    useEffect(() => {
        getInvestors({ append : false})
        getExperts({ append : false})
        onCloseChatBox()
    }, [])
    

    return (
        <Flex className="w-[100%] flex-col overflow-hidden">
            <Header title='Dashboard' />
            <Flex className="w-[100%] gap-5" flexDir={!isDesktop ? "column" : "row"} h={isDesktop ? "100%" : "auto"} w="100%" p={4} overflow="hidden" >
                <PageHeader  hide={!isDesktop ? false : true}  />
                <VStack className="min-w-[80vw] xs:min-w-[350px] min-h-max relative">
                    <Tabs className={`w-[90vw] ${!isDesktop ? 'max-w-[450px]' : 'max-w-[350px]'}`} index={tabIndex} onChange={handleTabsChange} isFitted>
                        <TabList mb='1em'>
                            <Tab>Investors</Tab>
                            <Tab>Experts</Tab>
                        </TabList>
                    </Tabs>
                    <Box className={`h-full hide-scroll ${isDesktop ? 'max-h-[75vh] overflow-y-auto' : ''}`}>
                        {
                            tabIndex ?  (
                                <ConnectCard 
                                    containerRef={expertsContainerRef} 
                                    buttonSize={{w:'90px', h: '40px'}} 
                                    onSelect={onSelectUser} 
                                    isLoading={isEXPLoading} 
                                    data={expertsSliceCount > 0 ? experts.slice(0, experts.length - expertsSliceCount * sliceBy) : experts} 
                                    userType={"Experts"} 
                                    onScroll={() => {handleScroll(expertsContainerRef.current.scrollTop + expertsContainerRef.current.clientHeight, expertsContainerRef.current.scrollHeight, false)}}
                                    selectedUser={selectedUser}
                                    goToDetailsPage={true}
                                    onCompleteAction={onClickUserConnectionButton}
                                
                                />
                            ):  (
                                <ConnectCard 
                                    containerRef={investorsContainerRef} 
                                    buttonSize={{w:'90px', h: '40px'}} 
                                    onSelect={onSelectUser} 
                                    isLoading={isINVLoading} 
                                    data={investorSliceCount > 0 ? investors.slice(0, investors.length - investorSliceCount * sliceBy) : investors} 
                                    userType={"Investors"} 
                                    onScroll={() => {handleScroll(investorsContainerRef.current.scrollTop + investorsContainerRef.current.clientHeight, investorsContainerRef.current.scrollHeight)}}
                                    selectedUser={selectedUser}
                                    onCompleteAction={onClickUserConnectionButton}
                                />
                            )
                        }
                        
                    </Box>
                    <Box className={`w-full flex text-xs font-semibold p-2 px-4 justify-between ${isDesktop ? "absolute bottom-7" : "gap-5 items-center flex-wrap max-w-sm mx-auto"}`}>
                        <Text className="underline cursor-pointer" onClick={() => {loadLess(!tabIndex ? true : false)}}>Load Fewer</Text>
                        <Text className="underline cursor-pointer" onClick={() => {loadMore(!tabIndex ? true : false)}}>Load More</Text>
                    </Box>
                </VStack>
                <Box className={`hide-scroll ${isDesktop ? 'max-h-[90vh] overflow-y-auto max-w-[800px]' : ''}`}>
                <Flex className={`flex-col w-[100%] h-full min-h-max`} >
                        <PageHeader  hide={isDesktop ? false : true}  />
                        <Flex flexDirection={"column"} className="w-[100%] h-full gap-4">
                            <AdvertFeature />
                            <StartupOffersCard/>
                        </Flex>
                </Flex>
                </Box>
            </Flex>


            <Flex hidden={isDesktop ? false : true} className="fixed items-end min-w-max right-10 gap-8 z-10 bottom-0">    
                <Flex className="bg-white" boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)">
                {chatBoxIsOpen && (
                    <Box w={"480px"} h="80vh max-h-[300px]">
                        <ChatBox advert={selectedAdvert} advertId={advertId} isAdvert={isAdvert} setConnections={setConnections} isFromNavigate={isFromNavigate} recipientId={recipientId} setRecipient={setRecipient} recipient={recipient} closeChatBox={closeChatBox} refetchConnections={refetchConnections} />
                    </Box>
                )}
                </Flex>
                <Flex  boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)" className="flex-col bg-white min-w-[300px] z-10">
                    <Flex onClick={openMessageTray} bgColor="#134A70" className="items-center cursor-pointer justify-between py-2 px-3">
                        <Text
                            fontSize={{ base: 14}}
                            fontWeight={800}
                            ml={{ base: "3rem", lg: 0 }}
                            color="#fff"
                        >
                            Messaging
                        </Text>
                        <Box>
                        {
                            showMessageTray ? <ChevronDownIcon w={7} h={7} color="white"  /> : <ChevronUpIcon w={7} h={7} color="white"  />
                        }
                        </Box>
                    </Flex>
                    <Box className="max-h-[60vh] overflow-y-auto">
                        {
                            showMessageTray ? (
                                connectionsLoading ? <StyledSpinner /> : <>{[...(inboxAdverts?.data?.adverts || []), ...connections]?.length > 0 ? [...(inboxAdverts?.data?.adverts || []), ...connections.filter((b) => b.accepted === true)]?.sort(sortFn)?.map((connection, index) => connection?.advert_type ? <AdvertMessage advert={connection} key={index} openChatBox={openAdvert} /> : <ConnectionMessage recipient={connection} key={index} openChatBox={openChatBox} />) : <Box className="text-center">No connections...</Box>}</>
                            ) : <Box py={1}></Box>
                        }
                    </Box>
                    
                </Flex>
            </Flex>  
        </Flex>
    );
}
 
export default Dashboard;