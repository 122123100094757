import { Box, Flex, Select, Text, useStatStyles, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";
import { setCompleteSurvey } from "../../redux-toolkit/reducers/SurveySlice";
import IsDesktop from "../../utils/IsDesktop";
import UserService from "../../utils/UserService";
import BrandButton from "../brandButton/BrandButton";

const StatusIndicator = () => {
  const accountType = useSelector((state) => state?.user?.userInfo?.userTypeTitle);
  const companyDetails = useSelector((state) => state?.company?.companyInfo);
  const allCompaniesInfo = useSelector((state) => state?.allcompanies?.allCompaniesInfo || [])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(0)
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0)

  const onChangeCurrentCompanyIndex = (e) => {
    setCurrentCompanyIndex(e.target.value)
    dispatch(setCompanyInfo(allCompaniesInfo[Number(e.target.value)]))
    UserService.setCompanyInfo(allCompaniesInfo[Number(e.target.value)])
    
  }

  useEffect(() => {
    if(accountType === "investor" || accountType === "founder"){
      let _status = 0
      if (companyDetails?.title) _status += 20
      if(companyDetails?.business_category_ids?.length > 0) _status += 16
      if(companyDetails?.business_model_ids?.length > 0) _status += 16
      if(companyDetails?.company_stage_ids?.length > 0) _status += 16
      if(companyDetails?.fundCategoryId) _status += 16
      if(companyDetails?.incorporation_location_ids?.length > 0) _status += 16
      setStatus(_status)
    }
    
  }, [
    companyDetails?.business_category_ids?.length,
    companyDetails?.business_model_ids?.length,
    companyDetails?.company_stage_ids?.length,
    companyDetails?.fundCategoryId,
    companyDetails?.incorporation_location_ids?.length,
    companyDetails?.title
  ])

  useEffect(() => {
    let index = allCompaniesInfo.findIndex(({id}) => id === companyDetails?.id)
    index >= 0 && setCurrentCompanyIndex(`${index}`)
  }, [companyDetails?.title])

  const completeSurvey = () => {
    UserService.setCompleteSurveyAction(true);
    dispatch(setCompleteSurvey(true));
    navigate("/survey");
  };

  const editSurvey = () => {
    UserService.setCompleteSurveyAction(true);
    dispatch(setCompleteSurvey(true));
    navigate("/account-settings");
  };

  return (
    <>
      {accountType === "founder" && (
            <VStack spacing={3} justifyContent="center" alignItems="flex-start">
              <Flex bg={`conic-gradient(${status === 100 ? "green 360deg" : `#134A70 ${Math.ceil(status*3.6)}deg`}, lightgray 0deg)`} borderRadius="50%" justifyContent="center" alignItems="center" w="65px" h="65px" borderTopColor="">
                <Flex justifyContent="center" alignItems="center" w="90%" h="90%" bg="white" borderRadius="50%">
                  <Text color="green" fontWeight={700}>
                  {`${status}%`}
                  </Text>
                </Flex>
              </Flex>
              <Box>
                <Text fontSize="14px" color="brand.primary">
                  Profile Status
                </Text>
                <Text fontWeight={600} fontSize="16px" color="brand.primary">
                  {status === 100 ? 'Complete!' : 'Not Complete!'}
                </Text>
                </Box>
              <BrandButton text="Complete" colorScheme="blue" fontSize="14px" onClick={editSurvey} />
              <Box className={`mt-10 flex md:hidden flex-col gap-1`}>
                <Text className="text-sm font-semibold pt-10">Companies</Text>
                <Select className="max-w-[180px]" value={currentCompanyIndex} onChange={onChangeCurrentCompanyIndex} placeholder={''}>
                    {
                      allCompaniesInfo.map((_company, index) => (
                        <option key={index} value={index}>{_company.title}</option>
                      ))
                    }
                </Select>
              </Box>
            </VStack>
      )}
      {accountType === "investor" && (
        <VStack spacing={3} justifyContent="center" alignItems="flex-start">
          <Flex bg={`conic-gradient(${status === 100 ? "green 360deg" : `#134A70 ${Math.ceil(status*3.6)}deg`}, lightgray 0deg)`} borderRadius="50%" justifyContent="center" alignItems="center" w="65px" h="65px" borderTopColor="">
            <Flex justifyContent="center" alignItems="center" w="90%" h="90%" bg="white" borderRadius="50%">
              <Text color="green" fontWeight={700}>
              {`${status}%`}
              </Text>
            </Flex>
          </Flex>
          <Box>
            <Text fontSize="14px" color="brand.primary">
              Profile Status
            </Text>
            <Text fontWeight={600} fontSize="16px" color="brand.primary">
              {status === 100 ? 'Complete!' : 'Not Complete!'}
            </Text>
          </Box>
          <BrandButton text="Edit" fontSize="14px" onClick={editSurvey} />
        </VStack>
      )}
    </>
  );
};
export default StatusIndicator;
