import {
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  Select,
  InputLeftAddon,
  Text,
  Textarea,
  VStack,
  Spinner,
  Button,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useData } from "../../../data";
import { mutateFunction, useMutation } from "../../../libs/apis";
import IsDesktop from "../../../utils/IsDesktop";
import { useSelector } from "react-redux";

const AddBrand = ({ onToggle, refetch }) => {
  const isDesktop = IsDesktop();
  const { data } = useData(`brands`);
  const [offerData, setOfferData] = useState({});
  const [brands, setBrands] = useState([]);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);

  const { addToast } = CustomToast();
  const addStartupOffer = () => {

    //console.log(loggedInUserId,'dkdkdkkd')
    const data = new FormData();

    if (profilePicture !== null || profilePicture != undefined) {
      data.append("logo", profilePicture);
    }
    data.append("userId", loggedInUserId)

    for (let key in offerData) {
      data.append(key, offerData[key]);
    }

    mutate(
      { key: `brands`, method: "post", data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
          onToggle();
        },
      }
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "brand_id") {
      setOfferData((prev) => ({
        ...prev,
        [e.target.name]: Number(e.target.value),
      }));
    } else {
      setOfferData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };
  useEffect(() => {
    if (data?.data) {
      setBrands(data?.data?.brands);
    }
  }, [data]);

  const handleChangeForFile = (e, type) => {
    const fileData = e.target.files[0];
    setProfilePicture(fileData);
    if (fileData) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(fileData);
    }

    // uploadPhoto(formData, type);
    // uploadPhoto({attachment: fileData})
    // uploadPhoto(e.target.files[0]);
  };
  return (
    <VStack alignItems="flex-start">
      <Flex justifyContent="flex-start" alignItems="center" my={4} mx={4}>
        <Flex
          cursor="pointer"
          onClick={onToggle}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
      </Flex>
      <Box mt="1.25rem">
        <Flex>
          {preview ? (
            <img src={preview} alt="Preview" style={{ width: "100px" }} />
          ) : (
            <Image src="/assets/images/Ellipse 3 (1).png" alt="image" />
          )}

          <Button
            mt="1.25rem"
            ml="0.625rem"
            // border="1px solid #000000"
            // borderRadius="20px"
          >
            <Input
              accept="image/*"
              hidden
              id="image-input"
              type="file"
              onChange={(e) => handleChangeForFile(e, "profile")}
              name="avatar"
              key="avatar"
            />
            <label htmlFor="image-input">
              <VStack>
                <Box
                  cursor="pointer"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="transparent"
                  h="24px"
                  w="96px"
                  border="1px solid #000"
                  borderRadius="15px"
                  fontSize={10}
                  mt={5}
                >
                  Choose logo
                </Box>
                {uploading ? (
                  <Flex>
                    <Text fontSize="12px">Uploading...</Text>
                    <Spinner />
                  </Flex>
                ) : (
                  <></>
                )}
              </VStack>
            </label>
          </Button>
        </Flex>
      </Box>
      <HStack h="500px" w="100%" alignItems="flex-start" m={4}>
        <VStack w="60%" alignItems="flex-start">
          <Flex flexDir="column" w="100%" key="name">
            <Text fontSize="14px" mb={2}>
              Name
            </Text>
            <Input
              borderWidth="1px"
              borderColor="brand.primary"
              borderRadius="5px"
              focusBorderColor="#"
              variant="outline"
              placeholder="Name"
              name="name"
              type="text"
              w="100%"
              h="50px"
              onChange={(e) => handleChange(e)}
            />
          </Flex>
          <Flex flexDir="column" w="100%" h="100%" key="details" mx={4}>
            <Text fontSize="14px" mb={2}>
              Brand Details
            </Text>
            <Textarea
              borderWidth="1px"
              borderColor="brand.primary"
              borderRadius="5px"
              focusBorderColor="#"
              variant="outline"
              placeholder="Offer Details"
              name="description"
              type="text"
              h="100%"
              w="100%"
              onChange={(e) => handleChange(e)}
            />
          </Flex>

          {/* <Flex flexDir="column" w="100%" key="price">
            <Text fontSize="14px" mb={2}>
              Offer Value
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer value" name="offer_value" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex> */}

          <BrandButton
            isLoading={mutateIsLoading}
            text="Add Brand"
            w="120px"
            colorScheme="blue"
            onClick={() => addStartupOffer()}
          />
        </VStack>
        {/* <Flex flexDir="column" w="38%" h="100%" key="details" mx={4}>
          <Text fontSize="14px" mb={2}>
            Offer Details
          </Text>
          <Textarea
            borderWidth="1px"
            borderColor="brand.primary"
            borderRadius="5px"
            focusBorderColor="#"
            variant="outline"
            placeholder="Offer Details"
            name="description"
            type="text"
            h="100%"
            w="100%"
            onChange={(e) => handleChange(e)}
          />
        </Flex> */}
      </HStack>
    </VStack>
  );
};
export default AddBrand;
