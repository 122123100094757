import { Box, Flex } from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import Offers from "../../../components/startup-offers/Offers";
import IsDesktop from "../../../utils/IsDesktop";

const StartupOffers = () => {
    const isDesktop = IsDesktop()
    return (
        <Box>
            <Header title='Startup Offers' />
            <Flex >
                <Offers/>
            </Flex>
        </Box>
    );
}
 
export default StartupOffers;