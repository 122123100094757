import { Box, Image, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import IsDesktop from "../../../utils/IsDesktop";

const DashboardAdvertCard = ({advert}) => {
    const isDesktop = IsDesktop();
    const navigate = useNavigate();
    const goToAdvert = () => {
      navigate(`../view-advert?advertId=${advert.id}`);
    };
    return (
        <VStack minW={!isDesktop ? "280px" : ""} cursor="pointer"
        mx={!isDesktop ? 2 : ""} align="flex-start" 
        mb="32px" key={advert?.id} onClick={() => goToAdvert()}
        >
              {/* < w={IsDesktop() ? "300px" : "150px"} /> */}
              <Box w="100%">
                <Image className="w-full h-[768px]" src={advert?.media} alt={advert?.title} srcset="" rounded="lg" objectFit="cover" boxSize="100%" />
              </Box>
              <Text textAlign="left" fontSize="18px" justifyItems="left" className="font-semibold text-left">
                {advert?.title || "N/A"}
              </Text>
        </VStack>
    )
}
export default DashboardAdvertCard;