import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  Box,
  Flex,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import IsDesktop from "../../../utils/IsDesktop";

const CustomRadioGroup = ({
  question,
  options,
  data,
  setFilledData,
  filledData,
  responseId,
  isOnboardingView = true,
  containerClasses = '',
  radioElemContainerClasses = ''
}) => {
  const isDesktop = IsDesktop();

  const setFormData = (val) => {
    setFilledData((prev) => ({
      ...prev,
      [Object.keys(data)[0]]: parseInt(val),
    }));
  };

  // Find the option with value "saas"
  // const saasOption = options.find((item) => item.title === response);

  // console.log(saasOption, "ddddd", options);

  return (
    <VStack spacing={!isOnboardingView ? 1 : 4} alignItems="flex-start" className={`${!isOnboardingView ? 'w-full' : ''}`}>
      <Box 
        className={`${isOnboardingView ? isDesktop ? "w-[600px]" : "w-[300px]" : '' } ${containerClasses}`}
      >
        <Text fontWeight={700} fontSize="16px" color="brand.primary">
          {question} {!isOnboardingView && (typeof filledData[Object.keys(data)[0]] === "object" ? !filledData[Object.keys(data)[0]]?.length : !filledData[Object.keys(data)[0]] ) && <Text className="text-red-600 inline font-bold">*</Text>}
        </Text>
      </Box>

      <RadioGroup
        defaultValue={`${responseId}`}
        onChange={(e) => setFormData(e)}
      >
        <Flex
          flexDir="column"
          alignItems="flex-start"
          className={`${isOnboardingView ? isDesktop ? "max-h-[200px]" : "max-h-[600px]" : '' } h-full ${radioElemContainerClasses}`}
          flexWrap="wrap"
          alignContent="flex-start"
        >
          {options.length &&
            options.map((item) => (
              <Box
                key={item.id}
                m={isDesktop ? 2.5 : 1.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Radio
                  value={`${item.id}`}
                  // defaultChecked={item.title === response} // Check if it's the "saas" option
                >
                  {item.title || item.name}
                </Radio>
              </Box>
            ))}
        </Flex>
      </RadioGroup>
    </VStack>
  );
};

export default CustomRadioGroup;
