export const publicUrls = {
    default: () => "",
    home: () => "home",
    homes: () => "homes",
    signUp: () => "signUp",
    login: () => "login",
    forgotPassword: () => "forgot-password",
    verifyEmail: () => "verify-email",
    VerifyAccountDeletion: () => "remove-user",
    privacyPolicy: () => "privacy-policy",
    resetPassword: () => "reset-password",
  }

export const foundersUrl = {
  dashboard: () => "dashboard",
  connections: () => "connections",
  investors: () => "investors",
  startupOffers: () => "startup-offers",
  bookings: () => "bookings",
  experts: () => "experts",
  accountSettings: () => "account-settings",
  allUsers: () => "users",
  advert: () => "advert",
  viewAdvert: () => "view-advert",
  pdfViewer: () => "account-settings/pdf-viewer",
  videoViewer: () => "account-settings/video-viewer",
} 

export const investorsUrl = {
  dashboard: () => "dashboard",
  founders:()=>"founders",
  advert: () => "advert",
  startupOffers: () => "startup-offers",
  experts: () => "experts",
  connections: () => "connections",
  accountSettings: () => "account-settings",
} 

export const expertsUrl = {
  dashboard: () => "dashboard",
  founders: () => "founders",
  connections: () => "connections",
  accountSettings: () => "account-settings"
}

export const adminUrl = {
  dashboard: () => "dashboard",
  accountSettings: () => "account-settings",
  subscriptions: () => "admin/subscriptions",
  allUsers: () => "users",
  userFounders:() => "founders",
  userExperts:() => "userexperts",
  userInvestors:() => "userinvestors",
  userAddExpert:() => "useraddexpert",
  viewUser:()=> "admin/view-user",
  startupOffers: () => "startup-offers",
  brands: () => "brands",
  adverts: () => "admin/adverts",
  deletedUsers: () => "admin/deleted-users"
} 
  
export const privateUrls = {
  survey: () => "survey",
  viewUser: () => "view-user"
}

