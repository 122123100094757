import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "./TimeSlots";
import client from "../../../libs/client";
import { BASE_URL } from "../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../data";
import { useSelector } from "react-redux";

const DailyBookingsCalendar = () => {
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const {data: bookingsData} = useData(`bookings`)
  const DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "S"];
  
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [bookingDates, setBookingDates] = useState([]);

  useEffect(()=>{
    if (selectedDate) {
      setFetching(true);
      client().get(`${BASE_URL}bookings/todays-booking?date=${selectedDate.format("YYYY-MM-DD")}`)
            .then((res) => {
              setSelectedDateBookings(res?.data?.data?.bookings)
              setFetching(false);
        })
    }
  },[selectedDate])
  useEffect(()=>{
    if (bookingsData) {
      const dates = bookingsData?.data?.bookings?.map((booking) => moment(booking?.slot_start_date).format("MM-DD-YYYY"))
      setBookingDates(dates)
    }
  },[bookingsData])

  const getBackgoundColor = (date) => {
    if(selectedDate.isSame(date, "day")) {
      return "brand.primary";
    } else if (bookingDates.includes(date.format("MM-DD-YYYY"))) {
      return "lightblue"
    } else {
      return "white"
    }
    // day.format("MM-DD-YYYY")
  }
  
  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(1, "week"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(1, "week"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };

  const renderDaysOfWeek = () => {
    return DAYS_OF_WEEK.map((day, index) => (
      <Box key={`${index}`} flex={1} textAlign="center">
        <Text fontSize="lg" fontWeight="bold">
          {day}
        </Text>
      </Box>
    ));
  };

  const renderCalendarDays = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const calendarDays = [];
    let day = startOfWeek;
    while (day <= endOfWeek) {
      calendarDays.push(day);
      day = day.clone().add(1, "day");
    }
    return calendarDays.map((day) => (
      <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center" py={2}  >
        <Box display="flex" justifyContent="center" alignItems="center" border={selectedDate.isSame(day, "day") ? "2px solid" : "none"} borderRadius="full" borderColor="brand.primary" w="35px" h="35px" cursor="pointer" onClick={() => setSelectedDate(day.clone().startOf("day"))} bg={getBackgoundColor(day)} color={selectedDate.isSame(day, "day") ? "white" : "brand.primary"}>
          <Text>
            {day.format("D")}
          </Text>
        </Box>
      </Box>
    ));
  };

  return (
    <Box display="flex" flexDir="column" h="100%" justifyContent="flex-start">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="2xl" fontWeight="bold">
            {currentDate.format("MMMM, YYYY")}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Input type="date" value={selectedDate.format("YYYY-MM-DD")} onChange={handleDateChange} />
        </Flex>
      </Flex>
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <IconButton aria-label="Previous Week" icon={<ChevronLeftIcon />} onClick={handlePrevWeekClick} size="sm" />
        <IconButton aria-label="Next Week" icon={<ChevronRightIcon />} onClick={handleNextWeekClick} size="sm" />
      </Flex>
      <Flex mt={2}>
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {renderDaysOfWeek()}
      </Flex>
      <Flex mt={2}>
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {renderCalendarDays()}
      </Flex>
      <Box mt={2} h="100%" display="flex" flexDir="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize="18px" my={4}>
            Today's Bookings
          </Text>
          <BsThreeDots size="25px" />
        </Flex>
        <Box overflowY="auto" flex={1}>
        <TimeSlots bookings={selectedDateBookings} loading={fetching}/>
        </Box>
      </Box>
    </Box>
  );
};
export default DailyBookingsCalendar;
