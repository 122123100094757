import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Avatar,
  HStack,
  Divider,
  Heading,
  Spinner,
  Stack,
  Input,
  background,
} from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import BrandButton from "../brandButton/BrandButton";
import { useData } from "../../data";
import ConnectionButton from "../connectionButton/ConnectionButton";
import InvestorsPage from "./InvestorsPage";
import { useSelector } from "react-redux";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import { FiUpload } from "react-icons/fi";
import { useLocation, useSearchParams } from "react-router-dom";

const ViewInvestorDetails = ({ investor, onToggle }) => {
  const isDesktop = IsDesktop();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const { data, refetch } = useData(`users/${investor}`);
  const ref = useRef(null)
  const [userData, setUserData] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const [profilePicture, setProfilePicture] = useState(
    data?.data?.user?.cover_image
  );
  const { addToast } = CustomToast();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()



  const uploadPhoto = (data) => {
    mutate(
      { key: `users/${loggedInUser.id}/cover-image`, method: "put", data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };

  const handleChangeForFile = (e) => {
    const fileData = e.target.files[0];
    setProfilePicture(fileData);
    const formData = new FormData();
    formData.append("attachment", fileData);
    uploadPhoto(formData);
    // uploadPhoto({attachment: fileData})
    // uploadPhoto(e.target.files[0]);
  };

  const acceptRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: investor,
            usertype: 2
          })
          refetch()
        },
      }
    );
  }

  const declineRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: investor,
            usertype: 2
          })
          refetch()
        },
      }
    );
  }

  useEffect(() => {
    if (data?.data) {
      setUserData(data.data.user);
      setCompanyData(data.data.company);
    }
    if(ref?.current)ref.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }, [data]);

  useEffect(() => {

    if(userData){
      if(location?.search?.includes("status=accept")){
        acceptRequest()
      }
      if(location?.search?.includes("status=decline")){
        declineRequest()
      }
    }
    
  }, [userData, location?.search])
  return (
    <>
      {userData !== null ? (
        <>
          <Flex
            cursor="pointer"
            onClick={onToggle}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box mr={2}>
              <HiOutlineArrowNarrowLeft size="35px" />
            </Box>
            <Box>
              <Text fontWeight={600}>Back</Text>
            </Box>
          </Flex>
          <VStack
            pt={!isDesktop && 5}
            overflowY="scroll"
            minH="80vh"
            bg="white"
            borderRadius="10px"
            boxShadow="0.5px 2px 10px lightgray"
            justifyContent="start"
            alignItems="flex-start"
            className="relative hide-scroll"
          >
            {/* Daniel code changes here */}
            <div className="bg-gray-500 w-full min-h-[10rem] ">
              <div
                ref={ref}
                style={{
                  // border: "2px solid #ccc",
                  backgroundImage: `url(${data?.data?.user?.cover_image})`,
                  backgroundSize: "cover", // Adjust as needed (cover, contain, etc.)
                  backgroundRepeat: "no-repeat", // Adjust as needed (repeat, repeat-x, repeat-y)
                  borderRadius: "8px",
                }}
                // bg-[#ADCFE1]
                className="w-[100%] h-[10rem]"
              >
                {/* <img /> */}
                <Input
                  accept="image/*"
                  hidden
                  id="image-input"
                  type="file"
                  onChange={(e) => handleChangeForFile(e)}
                  name="avatar"
                  key="avatar"
                />
                <div
                  style={{
                    // border: "2px solid red",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="p-[20px]"
                >
                  <label htmlFor="image-input">
                    {/* <FiUpload className="text-[#134A70] text-[30px] font-bold cursor-pointer" /> */}
                  </label>
                </div>
              </div>
            </div>
            <div className={`flex  ${isDesktop ? 'w-[80%]' : 'w-full'} flex-col items-start relative top-[-60px] px-5 lg:px-4 2xl:px-8`}>
              <Box
                p={1}
                borderRadius="50%"
                borderColor="lightgray"
                borderWidth={2}
              >
                <Avatar
                  name={`${userData?.first_name} ${userData?.last_name}`}
                  src={userData?.profile_image}
                  size={isDesktop ? "2xl" : "xl"}
                />
              </Box>
              <div className={`flex ${isDesktop ? 'flex-row gap-x-32 space-x-20' : 'flex-wrap  gap-x-10 xs:gap-x-24'} gap-3 mb-5 xs:mb-2`}>
                <Box
                  className="items-center justify-center"
                  display="flex"
                  flexDir="column"
                  alignItems={!isDesktop ? "flex-start" : "center"}
                >
                  <Text className="text-2xl font-bold">
                    {`${userData?.first_name} ${userData?.last_name}`}
                  </Text>
                  {/* <Text textAlign="center" fontWeight={300} fontSize="10px">
                                            Joined {new Date(userData.createdAt).toLocaleDateString()}
                                        </Text> */}
                </Box>
                {/* <BrandButton text="Connect" width="100px" colorScheme="blue"/> */}

                <ConnectionButton
                  style={{ backgroundColor: "white" }}
                  user={userData}
                />
              </div>
            </div>
            <div
              display="flex"
              h=""
              alignItems="start"
              style={{ marginTop: "-60px" }}
              className="px-5 lg:px-4 2xl:px-8 mt-[-60px] w-full"
            >
              {/* <Box bg="red"  d="flex" alignContent="center" flexDir="column"> */}

              <div className="flex flex-col w-full">
                {isDesktop && <Divider orientation="vertical" />}
                <Box
                  d="flex"
                  flexDir="column"
                  justifyContent="center"
                  alignItems="center"
                  h="90%"
                  // mt={5}
                  w={isDesktop ? "800px" : "100%"}
                >
                  {/* <Heading
                    color="brand.primary"
                    fontSize={isDesktop ? 24 : 18}
                    fontWeight={700} className={`whitespace-nowrap`}
                    mb={2}
                    mt={isDesktop ? 0 : 5}
                  >
                    Interest Details
                  </Heading> */}
                  <Box h="100%" overflowY="auto" w="full">
                    <Box
                      d="flex"
                      flexDir={isDesktop ? "row" : "column"}
                      overflowY="auto"
                      fontSize={{ base: 12, "2xl": 16 }}
                      className="w-full"
                    >
                      <Box
                        className="flex flex-col gap-2"
                      > 
                        <Flex 
                        className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                        h={{md: '3vh', "2xl": "24px" }}
                        >
                          <Text
                            w={{lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            {isDesktop ? `Company Name` : `Name`}:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                            //align="right"
                          >
                            {companyData?.title}
                          </Text>
                        </Flex>
                        <Flex 
                          className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                          h={{md: '3vh', "2xl": "24px" }}
                          >
                          <Text
                            w={{ lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            {isDesktop ? `Company Stage` : `Stage`}:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                          >
                            {companyData?.company_stage?.map((item) => item?.title).join(', ')}
                          </Text>
                        </Flex>
                        <Flex 
                          className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                          h={{md: '3vh', "2xl": "24px" }}
                          >
                          <Text
                            w={{lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            Kind of fund:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                          >
                            {companyData?.fund_category?.title}
                          </Text>
                        </Flex>
                        <Flex 
                        className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                        h={{md: '3vh', "2xl": "24px" }}
                        >
                          <Text
                            w={{ lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            {isDesktop ? `Location of company` : `Locations`}:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                          >
                            {companyData?.incorporation_location?.map((item) => item?.name).join(', ')}
                          </Text>
                        </Flex>
                        <Flex 
                          className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                          h={{md: '3vh', "2xl": "24px" }}
                        >
                          <Text
                            w={{ lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            Business model:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                          >
                            {companyData?.business_model?.map((item) => item?.title).join(', ')}
                          </Text>
                        </Flex>
                        <Flex 
                          className={`${isDesktop ? 'flex-row' : ''} gap-4 items-center w-full md:flex-row`}
                          h={{md: '3vh', "2xl": "24px" }}
                          >
                          <Text
                            w={{ lg: 160 }}
                            fontSize={{ base: 12, lg: 14 }}
                            fontWeight={700} className={`whitespace-nowrap`}
                            color="#9FA7AD"
                          >
                            Category:
                          </Text>
                          <Text
                            fontWeight={{ base: 400, "2xl": 700 }}
                            color="#000"
                            fontSize={{ base: 12, lg: 14 }}
                          >
                            {companyData?.business_category?.map((item) => item?.title).join(', ')}
                          </Text>
                        </Flex>
                        {/* <HStack spacing={12} h={{base:'4vh', '2xl':'6.7vh'}}>
                                                <Text w={{base: 59, lg: 160}} fontSize={{base: 12, lg: 14}} fontWeight={700} className={`whitespace-nowrap`} color='#9FA7AD'>File(s)</Text>
                                                <Text></Text>
                                            </HStack> */}
                      </Box>
                      <Box className={`mt-2`}>
                        <Text color="#9FA7AD" fontWeight={700} className={`whitespace-nowrap`} fontSize={{ base: 12, lg: 14 }}>
                          Description:
                        </Text>
                        <Text fontSize={{ base: 12, lg: 14 }}>
                          {companyData?.description}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              {/* </Box> */}
            </div>
            <div className="px-5 lg:px-4 2xl:px-8 mt-8 ">
              <div className="text-2xl text-[#134a70] font-bold" style={{marginTop:'16px'}}>Discover and connect with potential investors!</div>
              <div className=" py-2">
              <InvestorsPage/>
              </div>
            </div>
          </VStack>
        </>
      ) : (
        <div className="">
          
          <Spinner />
        </div>
      )}
    </>
  );
};
export default ViewInvestorDetails;
