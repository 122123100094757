import { Box, Flex, Input, Text, useDisclosure, Tabs, TabList, Tab, InputGroup, InputLeftElement, TabPanels, TabPanel } from "@chakra-ui/react";
import BookingModal from "../../../../components/modal/BookingModal";
import BrandButton from "../../../../components/brandButton/BrandButton";
import IsDesktop from "../../../../utils/IsDesktop";
import { SearchIcon } from "@chakra-ui/icons";
import { useData } from "../../../../data";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Slot from "./Slot";
import StyledSpinner from "../../../../components/spinner/Spinner";
import ViewSlot from "./ViewSlot";

const MySlots = () => {
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { isOpen: addSlotModalIsOpen, onOpen: onOpenAddSlotModal, onClose: closeAddSlotModal } = useDisclosure();
    const isDesktop = IsDesktop();
    const [slots, setSlots] = useState([]);
    const today = moment();
    const nextMonth = moment(today).add(1, "month");
    const { data, isLoading, refetch } = useData(`experts/${loggedInUserId}/booking-slots?from=${today.format("YYYY-MM-DD")}&to=${nextMonth.format("YYYY-MM-DD")}`);
    const {isOpen: viewSlotModalIsOpen, onOpen: onOpenViewSlotModal, onClose: onCloseViewSlotModal} = useDisclosure();
    const [selectedSlot, setSelectedSlot] = useState();

  const addSlot = () => {
      onOpenAddSlotModal();
  }
  const openSlot = (slot) => {
    setSelectedSlot(slot)
    onOpenViewSlotModal();

  }

  useEffect(()=> {
    if (data?.data) {
      setSlots(data?.data?.slots);
      
    }
    }, [data])

  return (
    <>
    <ViewSlot slot={selectedSlot} isOpen={viewSlotModalIsOpen} onClose={onCloseViewSlotModal} />
    <BookingModal isOpen={addSlotModalIsOpen} onClose={closeAddSlotModal} refetch={refetch} />
    <Box>
      <Flex mb={6} w="100%" justifyContent="space-between" alignItems="center">
        <Text color="brand.primary" fontSize={isDesktop ? "20px" : "14px"} fontWeight={700}>
          My Slots
        </Text>
        <BrandButton text="Add Slot" width="100px" onClick={addSlot} />
      </Flex>
      <Box w={"100%"} h="100%">
              <Tabs minW="100%" rounded="10px" mr="20px" mt="0px" p="0" variant="unstyled">
                <TabList h="100%">
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    All
                  </Tab>
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    Available
                  </Tab>
                  <Tab h="100%" _focus={{ outline: "0 !important", color: "white" }} _selected={{ outline: "0 !important", color: "brand.primary", borderBottom: "2px", borderColor: "brand.primary", paddingBottom: "0px" }} color="gray" fontSize="14px">
                    Booked
                  </Tab>
                </TabList>
                <InputGroup mx={4} my={4} w="95%" border="1px" borderColor="brand.primary" borderRadius="5px">
                  <InputLeftElement pointerEvents="none" children={<SearchIcon color="#134A70" />} mr={0} />
                  <Input w="100%" outline="none" />
                </InputGroup>
                <TabPanels mt={-4}>
                  <TabPanel p={0} m={0} h="800px" overflowY="auto">
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "80%" : "80%"}>
                      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
                      {isLoading ? <StyledSpinner /> : <>{slots?.length > 0 ? slots?.sort((a, b) => moment(a?.slot_start_date).diff(b?.slot_start_date, 'days'))?.map((slot, index) => <Slot slot={slot} key={index} openSlot={openSlot} />) : <>Click the add slot button to create a slot..</>}</>}
                    </Box>
                  </TabPanel>
                  <TabPanel p={0} m={0} h="800px" overflowY="auto">
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "80%" : "502px"}>
                      {isLoading ? <StyledSpinner /> : <>{slots?.length > 0 ? slots?.filter((slot) => slot?.status === 'available')?.map((slot, index) => <Slot slot={slot} key={index} openSlot={openSlot} />) : <>No available slots...</>}</>}
                    </Box>
                  </TabPanel>
                  <TabPanel p={0} m={0} h="800px" overflowY="auto">
                    <Box flex={1} w={["100%", "auto"]} boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" p={4} mt={4} overflowY="auto" h={!isDesktop ? "80%" : "502px"}>
                    {isLoading ? <StyledSpinner /> : <>{slots?.filter((slot) => slot?.status === 'booked')?.length > 0 ? slots?.filter((slot) => slot?.status === 'booked')?.map((slot, index) => <Slot slot={slot} key={index} openSlot={openSlot} />) : <>No booked slots...</>}</>}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
    </Box>
  </>
  )
}
export default MySlots;