import { useState, useEffect } from "react";


const IsDesktop = () => {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 990);
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 990);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  
  return isDesktop;
}

export default IsDesktop;