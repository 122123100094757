import React, { useState, useEffect } from 'react';
import IsDesktop from '../../utils/IsDesktop';

const YouTubePreview = ({ url }) => {
  const [videoId, setVideoId] = useState(null);
  const [error, setError] = useState('');
  const isDesktop = IsDesktop()

  useEffect(() => {
    try {
      if (!url) {
        throw new Error('No URL provided');
      }

      const getVideoId = (url) => {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname === 'youtu.be'
          ? parsedUrl.pathname.split('/')[1]
          : new URLSearchParams(parsedUrl.search).get('v');
      };

      const id = getVideoId(url);
      if (!id) {
        throw new Error('Invalid YouTube URL');
      }

      setVideoId(id);
      setError(''); // Clear any previous errors
    } catch (err) {
      setError(err.message);
    }
  }, [url]);

  if (error) {
    return <div>
        {/* Error: {error} */}
        Invalid URL
    </div>;
  }

  if (!videoId) {
    return <div>Loading...</div>;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <iframe
      width={isDesktop ? `560` : `100%`}
      height="315"
      src={embedUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export default YouTubePreview;
