// import { axiosClient } from "libs";
// import { client } from "../client";
import axiosClient from '../client';
import UseLogoutTimer from "../../utils/UseLogoutTimer"

const fetchData = ({ queryKey, headers }) => {
	const {resetLogoutTimer} = UseLogoutTimer()
	return axiosClient()
	  .get(`/${queryKey}`, {headers})
	  .then((res) => {
		resetLogoutTimer()
		return res.data;
	  });
  };
export default fetchData;