import { 
    Table, 
    Avatar, 
    Box, 
    Flex, 
    Tbody, 
    Td, 
    Text,
    Th, 
    Thead, 
    Tr, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Textarea,
    Stack,
    useDisclosure
} from '@chakra-ui/react';
import moment from 'moment';
import BrandButton from '../brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import IsDesktop from '../../utils/IsDesktop';
import Spinner from '../spinner/Spinner';
import { FaRegCheckCircle,  FaRegTimesCircle } from "react-icons/fa";
import { mutateFunction, useMutation } from '../../libs/apis';
import { CustomToast } from '../toast/CustomToast';
import { useState } from 'react';


const ExpertTable = ({ listType = "upcoming", header = [], data = [], isLoading, refetch }) => {
    const {mutate, isLoading : mutateIsLoading} = useMutation(mutateFunction)
    const {mutate: rejectMutate, isLoading : rejectMutateIsLoading} = useMutation(mutateFunction)
    const isDesktop = IsDesktop();
    const navigate = useNavigate()
    const { addToast }= CustomToast()
    const [reason, setReason] = useState(null)
    const {isOpen, onClose, onOpen} = useDisclosure()
    const acceptBooking  = (id) => {
        mutate(
            { key: `experts/accept-booking/${id}`, method: "post", data: {} },
            {
              onSuccess(res) {
                addToast({message: 'Booking accepted'})
                refetch()
              },
            }
          );
    }

    const formSubmit = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        rejectMutate(
            { key: `experts/reject-booking/${id}`, method: "post", data: {reason} },
            {
              onSuccess(res) {
                addToast({message: 'Success'})
                refetch()
                setReason(null)
                onClose()
              },
            }
        );
    }

    return (
        <Box mt={6} overflowY='auto' borderRadius='10px 10px 10px 10px'>
            <Table whiteSpace='nowrap'>
                {isDesktop && <Thead>
                    <Tr
                        bg='rgba(19, 74, 112, 0.05)'
                        borderRadius='10px 10px 0px 0px'
                        boxShadow='0px 0px 8px 4px rgba(19, 74, 112, 0.1)'
                        borderBottom='1px solid #9FA7AD'
                    >
                        {header?.map(column => (
                            <Th key={column.key} color='#000' fontSize={{base: 12, '2xl': 16}}>{column.title}</Th>
                        ))}
                        <Th>{" "}</Th>
                    </Tr>
                </Thead>}

                <Tbody borderRadius='10px' h="100%" overflowY='auto' p={2}>
                        {(data.length === 0 && isLoading) && <Spinner mt={2}/>}

                        {data.sort((a, b)=>
                            moment(new Date(a.slot_start_date)).isBefore(moment(new Date(b.slot_start_date))) ? 1 : -1).map((booking) => (
                            <Tr
                                key={booking.id}
                                boxShadow='10px 10px 20px rgba(54, 171, 190, 0.05)'
                                borderRadius='10px'
                                bg='white.300'
                                maxH="60px"
                                p={2}
                            >
                                <Td>
                                    <Flex alignItems='center'>
                                        <Avatar className='cursor-pointer' onClick={() => {
                                            navigate(`/view-user?userid=${booking?.founder_id}&usertype=1`)
                                        }} size='xs' src={booking?.profile_image} name={booking?.first_name} mr={2} />
                                        <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{`${booking?.first_name} ${booking?.last_name}`}</Text>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.slot_start_date).format('DD/MM/YYYY')}</Text>
                                </Td>
                                {isDesktop && <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.slot_start_time, "hh:mm:ss").format("hh:mm")}</Text>
                                </Td>}
                                {isDesktop && <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.slot_end_time, "hh:mm:ss").format("hh:mm")}</Text>
                                </Td>}
                                <Td cursor='pointer'>
                                    <Flex className='gap-3 items-center justify-center'>
                                        {
                                            mutateIsLoading ? (
                                                <Spinner size="sm" />
                                            ) : booking?.status == 'pending' && listType == "upcoming" ? (
                                                <>
                                                    <FaRegCheckCircle color='green' fontSize={"23px"} onClick={() => {acceptBooking(booking?.booking_id)}}/>
                                                    <>
                                                    <Modal isOpen={isOpen} onClose={onClose} >
                                                            <ModalOverlay />
                                                            <ModalContent>
                                                            <ModalBody p={4}>
                                                                <Flex flexDirection="column">
                                                                <Stack spacing={6}>
                                                                    <Flex className="items-center gap-2" justify={"center"}>
                                                                    <Text
                                                                        fontSize={20}
                                                                        fontWeight={700}
                                                                        color="brand.primary"
                                                                        align="center"
                                                                    >
                                                                        Reason
                                                                    </Text>
                                                                    </Flex>

                                                                    <Text fontSize={14} color="#000000">
                                                                        Add the reason for a rejection (Optional)
                                                                    </Text>
                                                                </Stack>
                                                                <Box
                                                                    borderWidth="1px"
                                                                    borderColor="brand.primary"
                                                                    borderRadius="5px"
                                                                    alignItems="center"
                                                                    mt={"20px"}
                                                                >
                                                                    <form className='w-full' onSubmit={(e) => {formSubmit(e, booking?.booking_id)}} >
                                                                        <Textarea
                                                                            focusBorderColor="#"
                                                                            borderWidth="0px"
                                                                            variant="outline"
                                                                            name="reason"
                                                                            value={reason}
                                                                            onChange={(e) => setReason(e.target.value)}
                                                                            placeholder="Type in a brief reason...."
                                                                            w={"80vw"}
                                                                            maxWidth={"350px"}
                                                                            maxLength={150}
                                                                            rows={6}
                                                                        />
                                                                    </form>
                                                                </Box>
                                                                <Box className="mt-6 flex gap-8 w-full items-center justify-center">
                                                                    <BrandButton
                                                                        isLoading={rejectMutateIsLoading}
                                                                        text={`Submit`}
                                                                        onClick={(e) => {formSubmit(e, booking?.booking_id)}} 
                                                                        variant="md"
                                                                        bgColor="brand.primary"
                                                                        color="#fff"
                                                                        fontSize="14px"
                                                                        borderRadius={4}
                                                                        px={4}
                                                                        _hover={{ background: "brand.primary" }}
                                                                    />
                                                                    <BrandButton
                                                                        text="Close"
                                                                        onClick={onClose}
                                                                    />
                                                                </Box>
                                                                </Flex>
                                                            </ModalBody>
                                                            </ModalContent>
                                                        </Modal>
                                                        <FaRegTimesCircle color='red' fontSize={"23px"} onClick={onOpen}/>
                                                    </>
                                                </> 
                                            ) : <Text className='capitalize'>{booking?.status || ''}</Text>
                                        }
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
            {(data.length === 0 && !isLoading)? (
                <Text onClick={() => {navigate('/dashboard?slot=open')}} className='text-center w-full px-2 pt-10 text-gray-700 font-medium hover:font-semibold hover:underline cursor-pointer'>
                    You currently do not have any bookings! Click to add slot
                </Text>
            ) : null}
        </Box>
    );
}
 
export default ExpertTable;