import React, { useEffect, useState } from "react";
import { Box, Flex, Text, VStack, Avatar, HStack, Divider, Spinner } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import { useData } from "../../data";
import ExpertBookingCalendar from "./ExpertBookingCalendar";
import { useLocation, useSearchParams } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useSelector } from "react-redux";
import moment from "moment";


const ViewExpertDetails = ({expert, onToggle}) =>{
    const isDesktop = IsDesktop();
    const {data, refetch} = useData(`users/${expert}`)
    const [userData, setUserData] = useState(null)
    const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
    const { addToast } = CustomToast()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
    const loggedInUser = useSelector((state) => state?.user?.userInfo);
    const [slotId, setSlotId] = useState(null)
    const [slotDetails, setSlotDetails] = useState(null)
    const [relatedBookings, setRelatedBookings] = useState([])

    useEffect(()=>{
        if(data?.data){
            setUserData(data.data.user)
            setRelatedBookings((loggedInUser?.myBookings || []).filter(booking => {
              return booking?.expert_id === data?.data?.user?.id && moment().isSameOrBefore(booking?.expert_slot?.slot_end_date, 'day')
        }))
        }
    },[data])

    useEffect(() => {
      setSlotId(searchParams.get('slot_id'))
    }, [])

    

    useState(() => {

    }, [])


    useEffect(() => {
      
      const _ = () => {
        let _slotDetails;
        (loggedInUser?.myBookings || []).some((booking) => {
          if(slotId && booking?.slot_id == slotId){ 
            _slotDetails = booking.expert_slot
        }
          return booking?.slot_id == slotId
        })
        if(_slotDetails)setSlotDetails(_slotDetails)
      }
      _()
    }, [slotId])


    const acceptRequest =  () => {
        mutate(
          { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
          {
            onSuccess(res) {
              addToast(res);
              setSearchParams({
                userid: expert,
                usertype: 3
              })
              refetch()
            },
          }
        );
      }
    
      const declineRequest =  () => {
        mutate(
          { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
          {
            onSuccess(res) {
              addToast(res);
              setSearchParams({
                userid: expert,
                usertype: 3
              })
              refetch()
            },
          }
        );
      }


      useEffect(() => {
        if (userData) {
          if(location?.search?.includes("status=accept")){
            acceptRequest()
          }
          if(location?.search?.includes("status=decline")){
            declineRequest()
          }
        }
    }, [userData, location?.search]);

    return (
        <>
        {userData !== null ? (<>
            <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
                <Box mr={2}><HiOutlineArrowNarrowLeft size="35px"/></Box>
                <Box><Text fontWeight={600}>Back</Text></Box>
            </Flex>
            <VStack overflowY="auto" h="80vh" bg="white" borderRadius="10px" boxShadow="0.5px 2px 10px lightgray" justifyContent="space-between" alignItems="flex-start">
                <Flex h="100%" alignItems="center" p={isDesktop ? 10 : 2} justifyContent="center" w="100%">
                        <HStack flexDir={isDesktop ? "row" : "column"} h="100%" spacing={isDesktop ? 10 : 0} w="100%" alignItems="flex-start" justifyContent="flex-start">
                            <VStack w={isDesktop ? "50%" : "90%"} ml={isDesktop ? 5 : 0} mb={10} alignItems="flex-start" justifyContent="center">
                                <Box p={1} borderRadius="50%" borderColor="lightgray" borderWidth={2}>
                                    <Avatar name={`${userData?.first_name} ${userData?.last_name}`} src={userData?.profile_image} size={isDesktop ? "2xl" : "lg"}/>
                                </Box>
                                <Box>
                                    <Text fontWeight={700} fontSize={isDesktop ? "16px": "12px"}  my={4}>
                                        {`${userData?.first_name} ${userData?.last_name}`}
                                    </Text>
                                    
                                </Box>
                                <Box className="w-full" mt={4} overflowY="auto">
                                    <Text fontSize={isDesktop ? "16px": "12px"}  my={2}>
                                        About Expert
                                    </Text>
                                    <Text fontSize={isDesktop ? "16px" : "14px"}>
                                        {userData?.bio}
                                    </Text>

                                    <Text className="mt-7 mb-3 text-lg font-medium">Bookings</Text>
                                    {
                                      slotDetails?.id ? (
                                        <Box className="flex flex-col gap-2 my-4">
                                          <Text className="font-medium mb-1">Here are your booking details</Text>
                                          <Text>
                                            <strong>Title:</strong> {slotDetails?.slot_title}
                                          </Text>
                                          <Text>
                                            <strong>Date:</strong>  {moment(slotDetails?.slot_start_date).format('DD-MM-YYYY')}
                                          </Text>
                                          <Text>
                                            <strong>Time:</strong>  {moment(slotDetails?.slot_start_time, "kk:mm:ss").format('hh:mm a')} - {moment(slotDetails?.slot_end_time, 'kk:mm:ss').format('hh:mm a')}
                                          </Text>
                                        </Box>
                                      ) : relatedBookings?.length > 0 ? relatedBookings?.map((booking) => {
                                        return (
                                          <Box className="border-b border-blue-400 my-6 py-2 w-full">
                                             <Text>
                                              <strong>Title:</strong> {booking?.expert_slot?.slot_title}
                                            </Text>
                                            <Text>
                                              <strong>Date:</strong>  {moment(booking?.expert_slot?.slot_start_date).format('DD-MM-YYYY')}
                                            </Text>
                                            <Text>
                                              <strong>Time:</strong>  {moment(booking?.expert_slot?.slot_start_time, "kk:mm:ss").format('hh:mm a')} - {moment(booking?.expert_slot?.slot_end_time, 'kk:mm:ss').format('hh:mm a')}
                                            </Text>
                                            <Text className="capitalize" > <strong>Status:</strong> {booking.status}</Text>
                                          </Box>
                                        )
                                      }) : <Box className="my-8">
                                        <Text className="text-semibold text-sm text-center text-gray-600">You currently have no bookings with this expert.</Text>
                                      </Box>
                                    }
                                </Box>
                            </VStack>
                            {isDesktop && <Divider orientation="vertical"/>}
                            <VStack justifyContent="flex-start" alignItems="center" h="100%" mt={5} w={isDesktop ? "800px" : "100%"}>
  
                                <Box>
                                    <Text fontWeight={700} fontSize={isDesktop ? "16px": "12px"}  my={4}>
                                        Booking Hours with {`${userData?.first_name}`} (Seed A Founder)
                                    </Text>
                                </Box>
                                <Box h="100%" overflowY="auto" w="full">
                                    <Box d="flex" flexDir={isDesktop ? "row":"column"} >
                                        <Box>
                                            <Text fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Select a Day</Text>
                                            <Text color="gray" fontSize={{base: 12, lg: 14}}>Select a suitable day to have your call from the available days below.</Text>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" minW="280px" maxW="530px" p={2} m={2}>
                                            <ExpertBookingCalendar expert={expert} />
                                        </Box>
                                    </Box>
                                </Box>
                            </VStack>
                        </HStack>
                    {/* </Box> */}
                </Flex>
            </VStack>
        </>
        ):(
        <Box><Spinner/></Box>
        )}
        </>
    )
}
export default ViewExpertDetails;