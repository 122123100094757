import {
  Avatar,
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BrandButton from "../../../../components/brandButton/BrandButton";
import IsDesktop from "../../../../utils/IsDesktop";
import ConnectionButton from "../../../../components/connectionButton/ConnectionButton";
import YouTubePreview from "../../../../components/youtubePreview/youtubePreview";
import { useSelector, useDispatch } from "react-redux";
import { BsArrowLeft, BsFillHandThumbsUpFill, BsFillHandThumbsDownFill, BsXCircle } from "react-icons/bs";
import { setUserInfo } from "../../../../redux-toolkit/reducers/userSlice";
import { BASE_URL } from "../../../../helpers/constant";
import client from "../../../../libs/client";
import { StarIcon } from "@chakra-ui/icons";

const DashboardUserCard = ({
  user,
  onToggle,
  buttonSize = {},
  selectedUser,
  videoUrl,
  goToDetailsPage = true,
  userType="founders",
  onDislikeCompany = () => {},
  onCompleteAction  = () => {}
}) => {
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const loggedInUserStatus = useSelector((state) => state?.user?.userInfo?.status || "");
  const loggedInUserType = useSelector((state) => state?.user?.userInfo?.userTypeTitle || "");
  const viewDetails = () => {
    if(loggedInUserStatus == "disabled") return 
    goToDetailsPage && navigate(`/view-user?userid=${user?.id}&usertype=${user?.userTypeId}${user?.userTypeId == 1 ? `&cid=${user?.company?.id}` : ''}`);
  };
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state?.user?.userInfo);

  const likeCompany = async(e, companyId) => {
    try {
        e.stopPropagation()
        //let _founders = founders.filter(user => user.company.id !== companyId) 
        //updateState(_founders, false, false)
  
        if(userDetails?.likedCompanies?.includes(companyId)){
          return client()
          .delete(
            `${BASE_URL}remove-preference/${companyId}`
          )
          .then((res) => {
            dispatch(setUserInfo({
              ...userDetails,
              likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId)
            }))
          });
        }else{
          return client()
          .put(
            `${BASE_URL}like-company/${companyId}`
          )
          .then((res) => {
            dispatch(setUserInfo({
              ...userDetails,
              likedCompanies: [...userDetails.likedCompanies, companyId],
              disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
            }))
          });
        }
        
      } catch (e) {
        //console.log("the error====", e)
      }
  }
  
  const disLikeCompany = async(e, companyId) => {
      try {
        e.stopPropagation()
   
        if(userDetails?.disLikedCompanies?.includes(companyId)){
          return client()
          .delete(
            `${BASE_URL}remove-preference/${companyId}`
          )
          .then((res) => {
            dispatch(setUserInfo({
              ...userDetails,
              disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
            }))
          });
        }else{
          return client()
          .put(
            `${BASE_URL}dislike-company/${companyId}`
          )
          .then((res) => {
            dispatch(setUserInfo({
              ...userDetails,
              likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId),
              disLikedCompanies: [...userDetails.disLikedCompanies, companyId],
            }))

            onDislikeCompany(companyId)

          });
        }
        
      } catch (e) {
        //console.log("the error====", e)
      }
  }

  return (
    <>
      {
              userType === "founders" &&  loggedInUserType == "investor" && !isDesktop ? (
                <Flex className="justify-end gap-2">
                  <Tooltip label="remove from list">
                      <span>
                        <BsXCircle className="cursor-pointer mb-2" onClick={(e) => {disLikeCompany(e, user?.company?.id)}} fill={userDetails?.disLikedCompanies?.includes(user?.company?.id) ? 'red' : 'grey'} size={"26px"} />
                      </span>
                  </Tooltip>
                </Flex>
              ) : null
      }
      <Flex className="w-[100%] items-center p-2" justifyContent={"space-between"}>
        <Flex className="flex-wrap gap-2 flex-col xs:flex-row items-start xs:items-center">
          <Box py={1} onClick={viewDetails} cursor={"pointer"}>
            <Avatar
              borderRadius="100%"
              size={"md"}
              name={userType === "founders" ? `${user?.company?.title}` : `${user?.first_name} ${user?.last_name}`}
              src={user?.profile_image}
            />
          </Box>
          <Flex className="flex-wrap justify-center flex-col pb-3 xs:pb-0">
            <Heading
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              fontSize="0.9rem"
              maxWidth={isDesktop ? "120px" : "max-content"}
            >
              {userType === "founders" ? `${user?.company?.title}` : `${(user?.first_name + " " + user?.last_name).slice(0, 16)}${
                (user?.first_name + " " + user?.last_name).length > 16
                  ? "..."
                  : ""
              }`}
            </Heading>
            {userType === "experts" ? (<Text className="w-[15ch] text-ellipsis whitespace-nowrap overflow-hidden text-sm">
              {user?.position}
            </Text>) : null}
            {userType === "founders" && !isDesktop ? (<Flex className="flex-row gap-1 ml-1 items-center">
                <StarIcon h={3} w={3}color={user?.rating > 0 ? "yellow.400" : "initial"} />
                <StarIcon h={3} w={3} color={user?.rating > 1 ? "yellow.400" : "initial"} />
                <StarIcon h={3} w={3} color={user?.rating > 2 ? "yellow.400" : "initial"} />
             </Flex>) : null}
          </Flex>
        </Flex>
        {
          isDesktop && userType == "founders" ? (
            <Box>
              <BrandButton
                text="Profile"
                width="100px"
                colorScheme="transparent"
                onClick={viewDetails}
              />
            </Box>
          ) : (
            <Box>
              <ConnectionButton onCompleteAction={onCompleteAction} size={buttonSize} user={user} />
            </Box>
          )
        }
        
      </Flex>
      {!isDesktop && user?.id === user?.id && (
        userType !== "experts" ? (
          <div className="my-3 gap-1 flex flex-col px-2">
          <Text>
            <Text className="text-gray-600 inline">{userType === "founders" ? 'Founder:' : 'Company Name:'}</Text>{" "}
            {userType === "founders" ? `${user?.first_name} ${user?.last_name}` : user?.company?.title}
          </Text>
          <Text>
            <Text className="text-gray-600 inline">Company Stage:</Text>{" "}
            {user?.company?.company_stage.map(({ title }) => title).join(", ")}
          </Text>
          <Text>
            <Text className="text-gray-600 inline">Kind of fund:</Text>{" "}
            {user?.company?.fund_category?.title || ""}
          </Text>
          <Text>
            <Text className="text-gray-600 inline">Location of Company:</Text>{" "}
            {user?.company?.incorporation_location
              .map(({ name }) => name)
              .join(", ")}
          </Text>
          <Text>
            <Text className="text-gray-600 inline">Business Model:</Text>{" "}
            {user?.company?.business_model.map(({ title }) => title).join(", ")}
          </Text>
          <Text>
            <Text className="text-gray-600 inline">Category:</Text>{" "}
            {user?.company?.business_category
              .map(({ title }) => title)
              .join(", ")}
          </Text>
          <Text className="preview_message">
            <Text className="text-gray-600 inline">{userType === "experts" ? "About me: " : "Description: "}</Text>
            {user?.company?.description}
          </Text>

          {
            userType === "founders" ? (
              <Flex flexDirection={"column"} gap={4} className="mt-4 md:hidden">
                <Text className="text-gray-600">Startup Demo</Text>
                {videoUrl ? (
                  <YouTubePreview url={videoUrl} />
                ) : (
                  <>No youtube link</>
                )}
              </Flex>
            ) : null
          }
          
        </div>
        ) : (
          <div className="my-3 gap-1 flex flex-col px-2">
            <Text><Text className="text-gray-600 inline">Company Name:</Text> {user?.expert_price?.company_name || ""}</Text>
            <Text><Text className="text-gray-600 inline">Profession:</Text> {user?.position || ""}</Text>
            <Text><Text className="text-gray-600 inline">Location:</Text> {user?.expert_price?.location?.title || ""}</Text>
            <Text>
            <Text className="text-gray-600 inline">Description:</Text>
              {user?.bio}
            </Text>
        </div>
        )
      )}
      {/*

        <VStack alignItems="center" mx={isDesktop ? 2.5 : 0} borderRadius="10px" minW={isDesktop ? "180px" : "220px"} maxW={isDesktop ? "50%" : "70%"} style={{border:'solid #dadada 1px',padding:'8px'}}>
                <HStack onClick={viewDetails} alignItems="center" w="100%" style={{flexDirection:'column',height:'180px',cursor:'pointer'}}>
                <div className="my-0 bg-green-200  min-h-10 py-8 w-full mb-[-40px]"></div>
                    <Avatar borderRadius="100%" size={isDesktop ? "xl" : "lg"} name={`${user?.first_name} ${user?.last_name}`} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Young_girl_smiling_in_sunshine_%282%29.jpg/800px-Young_girl_smiling_in_sunshine_%282%29.jpg"}/>
                    <Box style={{margin:'0px 6px'}}>
                        <Box w="100%" mt={1}>
                            <Heading  textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="14px" maxWidth='150px' >
                            {`${user?.first_name} ${user?.last_name}`}
                            </Heading>
                            <Box overflow="hidden">
                                <Text textOverflow="ellipsis" w="100px" fontSize="12px" whiteSpace="nowrap" color="gray">
                                    {user?.position}
                                </Text>
                            </Box>
                        </Box>
                        <Box maxW="100%" h="120px" overflow="hidden" px={2} py={2} style={{paddingTop:'4px'}}>
                            <Text color="gray" fontSize="12px" textOverflow="ellipsis">
                                {user?.bio}
                            </Text>
                        </Box>
                    </Box>
                </HStack>
                <HStack spacing={2} alignItems="center" justifyContent="center">
                    <ConnectionButton user={user} />
                    {/* <BrandButton fontSize={!isDesktop && "12px"} text="View Details" width={isDesktop ? 130 : "110"} colorScheme="white" onClick={viewDetails}/> }
                </HStack>
            </VStack>
            */}
    </>
  );
};
export default DashboardUserCard;
