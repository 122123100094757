import React, { useContext, useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
  Input,
  HStack,
  Select,
} from "@chakra-ui/react";
import bell from "../../public/icons/bell.svg";
import IsDesktop from "../../utils/IsDesktop";
import { UserContext } from "../../context/UserContext";
import BrandButton from "../brandButton/BrandButton";
import { IoMdCheckmark } from "react-icons/io";
import logo from "../../public/icons/logo_.svg";
import rocket from "../../public/icons/rocket.svg";
import useData from "../../data/useData";
import Notifications from "../notification/Notifications";
import { useMutation, mutateFunction } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../utils/UserService";
import { setUserSubscriptionInfo } from "../../redux-toolkit/reducers/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";

const Header = ({ title, component }) => {
  
  const loggedInUser = useSelector(
    (state) => state?.user?.userInfo
  )
  const companyDetails = useSelector((state) => state?.company?.companyInfo);
  const expertDetails = useSelector((state) => state?.expert?.expertInfo);
  const loggedInUserSubscription = useSelector(
    (state) => state?.user?.userSubscriptionInfo
  );
  const allCompaniesInfo = useSelector((state) => state?.allcompanies?.allCompaniesInfo || [])
  const loggedInUserType = loggedInUser?.userTypeTitle || ""
  const dispatch = useDispatch();
  const isDesktop = IsDesktop();
  const {userInformation} = useContext(UserContext);
  const { isOpen, onToggle } = useDisclosure();
  const [page, setPage] = useState(0);
  const { data } = useData("products/subscriptions");
  const loggedInUserId = loggedInUser?.id || "";
  const loggedInUserStatus = loggedInUser?.status || ""
  const { data: connectionsData, isLoading } = useData(
    `users/${loggedInUserId}/connections?page_no=1&page_size=10`, 30000
  );
  const navigate = useNavigate()
  const accountInformationStatus = loggedInUserType === "admin" ? loggedInUserType : loggedInUserType === "expert" ? expertDetails?.status : companyDetails?.status
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [subscriptionPlans, setSubscriptionPlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPackage, setSelectedPackage] = useState();
  const { isOpen: isOpenNotifications, onToggle: onToggleNotifications } =
    useDisclosure();
  const [paymentData, setPaymentData] = useState({
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });

  const [allConnections, setAllConnections] = useState(
    connectionsData?.data?.connections ?? []
  );
  const [otherAllConnections, setOtherAllConnections] = useState([]);

  function handleChange(e) {
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  }

  const upgradeUser = (plan) => {
    setSelectedPlan(plan);
    setPage(page + 1);
  };

  const toggleNotifications = () => {
    if(loggedInUserStatus == "disabled") return 
    onToggleNotifications()
  }

  const fetchNotificationsForUser = async () => {
    return new Promise((resolve, reject) => {
      mutate(
        {
          key: `users/${loggedInUserId}/connections?page_no=1&page_size=10`,
          method: "get",
        },
        {
          onSuccess(res) {
            setAllConnections(res?.data?.connections);
            resolve(res);
          },
          onError(error) {
            reject(error);
          },
        }
      );
    });
  };

  const fetchOtherNotificationsForUser = async () => {
    return new Promise((resolve, reject) => {
      mutate(
        {
          key: `users/${loggedInUserId}/notification?page_no=1&page_size=10`,
          method: "get",
        },
        {
          onSuccess(res) {
            setOtherAllConnections(res?.data?.notifications);
            resolve(res);
          },
          onError(error) {
            reject(error);
          },
        }
      );
    });
  };

  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0)

  const onChangeCurrentCompanyIndex = (e) => {
    setCurrentCompanyIndex(e.target.value)
    dispatch(setCompanyInfo(allCompaniesInfo[Number(e.target.value)]))
    UserService.setCompanyInfo(allCompaniesInfo[Number(e.target.value)])
  }

  useEffect(() => {
    let index = allCompaniesInfo.findIndex(({id}) => id === companyDetails?.id)
    index >= 0 && setCurrentCompanyIndex(`${index}`)
  }, [companyDetails?.title])

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        await fetchNotificationsForUser();
        await fetchOtherNotificationsForUser();
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    // Set up a recurring interval to fetch notifications
    const intervalId = setInterval(fetchNotifications, 20000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  

  const checkout = () => {
    if (!selectedPackage) {
      return;
    } else {
      setPage(page + 1);
    }
  };

  const buyPlan = () => {
    setPage(page + 1);
  };
  const submitPaymentRequest = () => {
    const paymentDetails = {
      ...paymentData,
      subscriptionPlanId: selectedPlan.id,
      pattern: selectedPackage.toLowerCase(),
      currency: "usd",
    };
    mutate(
      { key: `subscriptions`, method: "post", data: paymentDetails },
      {
        onSuccess(res) {
          dispatch(setUserSubscriptionInfo(true));
          UserService.setUserSubscriptionInfo(true);
          addToast(res);
          setPage(page + 1);
          // queryClient.invalidateQueries('users');
          // queryClient.invalidateQueries('user-types');
        },
      }
    );
  };

  const displayUpgradeButton = () => {
    if (loggedInUserSubscription === true) {
      return false;
    } else if (
      loggedInUserSubscription &&
      typeof loggedInUserSubscription === "object"
    ) {
      if (loggedInUserSubscription?.is_active) {
        return false;
      }
      return true;
    }
  };

  const cancel = () => {
    setPage(0);
    setSelectedPlan({});
    setSelectedPackage();
    setPaymentData({});
    onToggle();
  };

  useEffect(() => {
    if (data?.data?.subscriptionPlans) {
      setSubscriptionPlans(data?.data?.subscriptionPlans);
    }
  }, [data?.data?.subscriptionPlans]);

  const CustomCheckBox = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="20px"
          h="20px"
        >
          <Box
            w="20px"
            h="20px"
            bg="rgba(19, 74, 112, 0.2)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IoMdCheckmark size="20px" color="brand.primary" />
          </Box>
        </Box>
      </>
    );
  };
  const selectPackage = (pack) => {
    setSelectedPackage(pack);
  };

  const OrderSummary = () => {
    return (
      <>
        <VStack my={5}>
          <VStack spacing={2}>
            <Image src={logo} />
            <Image src={rocket} boxSize="100px" />
            <Text color="brand.primary" fontWeight={600} fontSize="16px">
              Order Summary
            </Text>
          </VStack>
          <VStack spacing={4} w="300px">
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="14px">Plan</Text>
              <Text color="brand.primary" fontWeight={600} fontSize="16px">
                Premium
              </Text>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="14px">Payment Frequency</Text>
              <Text color="brand.primary" fontWeight={600} fontSize="16px">
                {selectedPackage}
              </Text>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="14px">Amount</Text>
              <Text color="brand.primary" fontWeight={600} fontSize="16px">
                $
                {selectedPackage === "Yearly"
                  ? selectedPlan?.amount
                  : selectedPlan?.monthly_amount}
              </Text>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="14px">Discount</Text>
              <Text color="brand.primary" fontWeight={600} fontSize="16px">
                -$2.00
              </Text>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text fontSize="14px">Total to pay</Text>
              <Text color="brand.primary" fontWeight={600} fontSize="16px">
                $
                {selectedPackage === "Yearly"
                  ? selectedPlan?.amount
                  : selectedPlan?.monthly_amount}
              </Text>
            </Flex>
            <Flex w="100%" justifyContent="center" alignItems="center">
              <BrandButton
                text="Cancel"
                w="100px"
                mx={2}
                onClick={() => cancel()}
              />
              <BrandButton
                text="Buy Plan"
                colorScheme="blue"
                w="100px"
                mx={2}
                onClick={() => buyPlan()}
              />
            </Flex>
          </VStack>
        </VStack>
      </>
    );
  };

  const PaymentDetails = () => {
    return (
      <>
        <VStack my={5}>
          <VStack spacing={2}>
            <Image src={logo} />
            <Image src={rocket} boxSize="100px" />
            <Text color="brand.primary" fontWeight={600} fontSize="16px">
              Payment Details
            </Text>
          </VStack>
          <Tabs
            w="40%"
            rounded="10px"
            mr="20px"
            mt="0px"
            p="0"
            variant="unstyled"
          >
            <TabList h="40%">
              <Tab
                h="40%"
                _focus={{ outline: "0 !important", color: "white" }}
                _selected={{
                  outline: "0 !important",
                  color: "brand.primary",
                  borderBottom: "2px",
                  borderColor: "brand.primary",
                  paddingBottom: "0px",
                }}
                color="gray"
                fontSize={!isDesktop ? "12px" : "16px"}
              >
                Credit Card
              </Tab>
              <Tab
                h="40%"
                _focus={{ outline: "0 !important", color: "white" }}
                _selected={{
                  outline: "0 !important",
                  color: "brand.primary",
                  borderBottom: "2px",
                  borderColor: "brand.primary",
                  paddingBottom: "0px",
                }}
                color="gray"
                fontSize={!isDesktop ? "12px" : "16px"}
              >
                Paypal
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0} m={0}>
                <VStack spacing={2}>
                  <Input
                    DefaultValue={paymentData.number}
                    key="number"
                    placeholder="Card Number"
                    name="number"
                    onChange={(e) => handleChange(e)}
                  />
                  <HStack spacing={2}>
                    <Input
                      DefaultValue={paymentData.exp_month}
                      key="exp_month"
                      placeholder="MM"
                      name="exp_month"
                      onChange={(e) => handleChange(e)}
                    />
                    <Input
                      DefaultValue={paymentData.exp_year}
                      key="exp_year"
                      placeholder="YY"
                      name="exp_year"
                      onChange={(e) => handleChange(e)}
                    />
                    <Input
                      DefaultValue={paymentData.cvc}
                      key="cvc"
                      placeholder="CVC"
                      name="cvc"
                      onChange={(e) => handleChange(e)}
                    />
                  </HStack>
                  <HStack spacing={2}>
                    <Input key="zip" placeholder="Zip" name="zip" />
                    <Input key="country" placeholder="Country" name="country" />
                  </HStack>
                </VStack>
                <HStack mt={5} w="100%" justifyContent="center">
                  <BrandButton
                    text="Cancel"
                    w="120px"
                    onClick={() => cancel()}
                  />
                  <BrandButton
                    colorScheme="blue"
                    text="Next"
                    w="70px"
                    isLoading={mutateIsLoading}
                    onClick={() => submitPaymentRequest()}
                  />
                </HStack>
              </TabPanel>
              <TabPanel p={0} m={0}>
                <Box>Paypal Payments</Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </>
    );
  };

  const ThankYouPage = () => {
    return (
      <>
        <VStack my={5}>
          <VStack spacing={2}>
            <Image src={logo} />
            <Image src={rocket} boxSize="300px" />
            <Text color="brand.primary" fontWeight={600} fontSize="16px">
              Payment Successful
            </Text>
            <Flex w="300px" alignItems="center">
              <Text textAlign="center" color="brand.primary" fontSize="14px">
                Lorem ipsum dolor sit amet consectetur. Donec sed neque
                phasellus sapien ut molestie non eget. Elementum facilisi tempus
                ut bibendum sit etiam. Brex Card
              </Text>
            </Flex>
            <BrandButton
              text="Close"
              w="100px"
              mx={2}
              onClick={() => cancel()}
            />
          </VStack>
        </VStack>
      </>
    );
  };

  const SelectedPlan = () => {
    return (
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          flexDir={isDesktop ? "row" : "column"}
          justifyContent="space-between"
        >
          <Box p={4} boxShadow="0px 2px rgba(19, 74, 112, 0.1)">
            {Object.keys(selectedPlan).length !== 0 ? (
              <Flex
                w="100%"
                h="400px"
                flexDir="column"
                justifyContent="flex-start"
                alignItems="center"
                p={2}
              >
                <Heading mb={2} fontSize="14px">
                  {selectedPlan?.title}
                </Heading>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                  display="flex"
                  w="100%"
                  h="80%"
                >
                  <Wrap
                    w="100%"
                    h="100%"
                    mt={5}
                    py={2}
                    px={2}
                    alignItems="center"
                    justify={isDesktop ? "flex-start" : "flex-start"}
                  >
                    {selectedPlan?.features.map((feature) => (
                      <WrapItem
                        mb={4}
                        w="48%"
                        display="flex"
                        flexDir="column"
                        key={feature}
                      >
                        <Flex w="100%" alignItems="center">
                          <Box mr={4}>
                            <CustomCheckBox />
                          </Box>
                          <Text>{feature}</Text>
                        </Flex>
                      </WrapItem>
                    ))}
                  </Wrap>
                  <Box
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                  >
                    <Flex
                      my={1}
                      border="1px"
                      borderColor={
                        selectedPackage === "Yearly"
                          ? "brand.primary"
                          : "lightgray"
                      }
                      borderRadius="5px"
                      w="60%"
                      h="60px"
                      px={10}
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => selectPackage("Yearly")}
                    >
                      <Box>
                        <Text fontWeight={600} color="brand.primary">
                          Pay Yearly
                        </Text>
                        <Text>
                          ${Math.ceil(selectedPlan?.amount / 12)}/month
                        </Text>
                      </Box>
                      <Box>
                        <Text>{selectedPlan?.amount}</Text>
                      </Box>
                    </Flex>
                    <Flex
                      my={1}
                      border="1px"
                      borderColor={
                        selectedPackage === "Monthly"
                          ? "brand.primary"
                          : "lightgray"
                      }
                      borderRadius="5px"
                      w="60%"
                      h="60px"
                      px={10}
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={() => selectPackage("Monthly")}
                    >
                      <Box>
                        <Text fontWeight={600} color="brand.primary">
                          Pay Monthly
                        </Text>
                        <Text>${selectedPlan?.monthly_amount}/month</Text>
                      </Box>
                      <Box>
                        <Text>${selectedPlan?.monthly_amount * 12}</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
                <BrandButton
                  mt={4}
                  w="40%"
                  text="Checkout"
                  colorScheme="blue"
                  onClick={() => checkout()}
                />
              </Flex>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const Plans = () => {
    return (
      <>
        <ModalHeader>
          <Text w="100%" textAlign="center" fontSize={14}>
            Choose your preferred plan
          </Text>
        </ModalHeader>
        <Box
          display="flex"
          flexDir={isDesktop ? "row" : "column"}
          justifyContent="space-between"
        >
          {subscriptionPlans?.length > 0 ? (
            subscriptionPlans.map((plan) => (
              <Box
                p={4}
                boxShadow="0 0 2px rgb(0 0 0 / 0.2)"
                borderRadius="15px"
                key={plan?.id}
              >
                <Flex
                  w="450px"
                  h="400px"
                  flexDir="column"
                  justifyContent="space-between"
                  alignItems="center"
                  border="1px"
                  borderRadius="15px"
                  borderColor="lightgrey"
                  p={2}
                >
                  <Box w="100%">
                    <Heading mb={2} fontSize="14px" w="100%" textAlign="center">
                      {plan?.title}
                    </Heading>
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      flexDir="column"
                      display="flex"
                      w="100%"
                    >
                      <Box mb={4}>
                        <Text>${plan?.amount}/year</Text>
                      </Box>
                      {plan?.features.map((feature) => (
                        <Box
                          mb={2}
                          w="100%"
                          display="flex"
                          flexDir="column"
                          key={feature}
                        >
                          <Flex w="100%" alignItems="flex-start">
                            <Box mr={4}>
                              <CustomCheckBox />
                            </Box>
                            <Text>{feature}</Text>
                          </Flex>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <BrandButton
                    w="95%"
                    text="Upgrade"
                    colorScheme="blue"
                    onClick={() => upgradeUser(plan)}
                  />
                </Flex>
              </Box>
            ))
          ) : (
            <></>
          )}
        </Box>
      </>
    );
  };

  return (
    <Box
      bgColor="#134A70"
      w="100%"
      className={`${!isDesktop ? "fixed top-0 flex items-center z-10" : ""}`}
    >
      {
        loggedInUserStatus == "disabled" ? (
          <Flex _hover={{backgroundColor: "#c68e8e"}}  className={`${!isDesktop ? "fixed" : ""} justify-center text-xs lg:text-sm bg-red-200 w-full text-center`}>
            This account is currently disabled
          </Flex>
        ) : accountInformationStatus === "draft" ? (
          <Flex _hover={{backgroundColor: "#c68e8e"}} onClick={() => {
            navigate('/account-settings')
          }} className={`${!isDesktop ? "fixed top-0" : ""} justify-center cursor-pointer text-xs lg:text-sm bg-red-200 w-full text-center`}>
            {`Your account is in draft mode. ${isDesktop ? `Click to update ${loggedInUserType === "expert" ? "expert" : "company"} details` : ""}`}
          </Flex>
        ) : null
      }
      {isOpenNotifications ? (
        <Notifications
          isOpen={isOpenNotifications}
          onClose={onToggleNotifications}
        />
      ) : (
        <></>
      )}
      <Flex
        h="100%"
        w="full"
        justifyContent="space-between"
        alignItems="center"
        //boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)"
        px={5}
        py={5}
      >
        {isDesktop && (
          <Text
            fontSize={{ base: 14, lg: 20 }}
            fontWeight={800}
            ml={{ base: "3rem", lg: 0 }}
            color="#fff"
          >
            {title}
          </Text>
        )}
        <Flex alignItems="center" className={`justify-end gap-1 ${!isDesktop ? 'w-full' : ''}`} >
          <Text
            mr={4}
            color="white"
            textTransform="capitalize"
            fontWeight={600}
            fontSize="16px"
          >
            {
              loggedInUserType == "founder" ? (
              <Box className={`md:flex hidden flex-col gap-1`}>
                <Select className="w-[220px]" value={currentCompanyIndex} onChange={onChangeCurrentCompanyIndex} placeholder={''}>
                    {
                      allCompaniesInfo.map((_company, index) => (
                        <option key={index} value={index}>{_company.title}</option>
                      ))
                    }
                </Select>
              </Box>
              ) : loggedInUserType !== "admin" ? userInformation?.firstName : loggedInUserType }
          </Text>
          {isDesktop && displayUpgradeButton() ? (
            <Button mr={6} onClick={() => onToggle()}>
              Upgrade
            </Button>
          ) : (
            <></>
          )}
          <div
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => toggleNotifications()}
          >
            <Image cursor="pointer" mr={3} w={25} src={bell} alt="bell-icon" />
            {
              otherAllConnections?.filter((othercon) => othercon.read === false)?.length != 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "white",
                  borderRadius: "10px",
                  width: "20px",
                  height: "20px",
                  top: "-5px",
                  right: "5px",
                }}
                className="absolute"
              >
                {otherAllConnections?.filter((othercon) => othercon.read === false)?.length}
              </div>
            )}
          </div>

          <Link to="/account-settings">
            <Avatar
              size="sm"
              name={`${userInformation?.firstName} ${userInformation?.lastName}`}
              src={userInformation?.profile_image}
            />
          </Link>
        </Flex>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={() => onToggle()}
        isCentered
        size={isDesktop ? "5xl" : "xs"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody overflowX="scroll">
            {page === 0 && Plans()}
            {page === 1 && SelectedPlan()}
            {page === 2 && OrderSummary()}
            {page === 3 && PaymentDetails()}
            {page === 4 && ThankYouPage()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Header;
