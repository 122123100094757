import React, { useState, useRef, useEffect, useContext } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Box, Image, Text, Flex, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, useDisclosure, Button, Link } from "@chakra-ui/react";
import { HamburgerIcon,ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import brandLogo from "../../public/icons/logo_.svg";
import logout from "../../public/icons/logout.svg";
import Navs from "./navs/nav";
import IsDesktop from "../../utils/IsDesktop";
import UserService from "../../utils/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../../redux-toolkit/reducers/authSlice";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { CustomToast } from "../toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";
import FounderSurveyForm from "../../pages/survey/FounderSurveyForm";
import { setCompleteSurvey } from "../../redux-toolkit/reducers/SurveySlice";
import StatusIndicator from "../statusIndicator/StatusIndicator";
import Logout from "../../utils/Logout";
import { UserContext } from "../../context/UserContext";

const Sidebar = ({sidebarWidth, toggleSidebar, isHidden}) => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const navs = Navs();
  const { activePage, setActivePage } = useContext(UserContext)
  const isDesktop = IsDesktop();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const { mutate } = useMutation(mutateFunction);
  const { isOpen: isSurveyOpen, onToggle: onToggleSurvey } = useDisclosure();
  const { doLogout } = Logout();
  const location =  useLocation()
  
  const completeSurvey = () => {
    UserService.setCompleteSurveyAction(true);
    dispatch(setCompleteSurvey(true));
    navigate("/survey");
  };

  const handleLogout = () => {
    mutate(
      { key: "logout", method: "get" },
      {
        onSuccess(res) {
          doLogout();
        },
      }
    );
  };

  const updateActivePage = (route) => {
    if(userInfo?.status == "disabled") return
    setActivePage(route)
  }

  useEffect(() => {
    navs.some((nav) => {
      if(location.pathname.match(new RegExp(`^${nav.to}`))){
        updateActivePage(nav.to)
        return true
      }
    })
  }, [location.pathname])

  useEffect(() => {
    if(userInfo?.status == "disabled") navigate("/dashboard")
  }, [location.pathname])

  

  // const [sidebarWidth, setSidebarWidth] = useState(300);

  // const toggleSidebar = () => {
  //   setSidebarWidth(sidebarWidth === 300 ? 50 : 300);
  // };

  return (
    <>
      {/* Mobile view */}
      {!isDesktop && (
        <>
          <Box className={`z-10 top-2 ${isOpen ? 'hidden' : ''}`} onClick={onOpen} color="#fff" pos="fixed" ml={3} pt={3} cursor="pointer" zIndex={"banner"} >
            <HamburgerIcon w={8} h={8} />
          </Box>
          <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton onClick={onClose} _focus={{ border: "none" }} outline="none" />
              <DrawerBody>
                <Box>
                  <Box d="flex" justifyContent="center" mt={10} ml={10} >
                    <Link href='https://seedafounder.com' isExternal><Image src={brandLogo} boxSize='150px' objectFit='cover' alt="BrandLogo" className="w-[300px]" /></Link>
                  </Box>
                  <Box>
                    {navs?.map((nav, index) => (
                      <Box
                        key={nav.to}
                        onClick={() => {
                          onClose();
                          if(userInfo?.status == "disabled") return 
                          setActivePage(nav.to);
                        }}
                      >
                        <NavLink to={userInfo?.status == "disabled" ? "#" : nav.to} style={{ textDecoration: "none" }} key={nav.name}>
                          <Box w="full" bgColor={activePage === nav.to && "rgba(19, 74, 112, 0.05)"} borderRadius={activePage === nav.to && "0px 30px 30px 0px"} borderLeft={activePage === nav.to && "4px solid #134A70"} mt={7} mx={0} p={2} d="flex" h="45px" justifyContent="center">
                            <Flex d="flex" flexDirection="row" justifyContent="left" alignItems="center" w="45vw">
                              {activePage === nav.to ? nav.activeIcon : nav.icon}
                              <Text fontSize={14} fontWeight={400} textAlign="left" color={activePage === nav.to ? "#134A70" : "#9FA7AD"}>
                                {nav.name}
                              </Text>
                            </Flex>
                          </Box>
                        </NavLink>
                      </Box>
                    ))}
                    <Box cursor="pointer" w="full" mt={5} d="flex" h="45px" justifyContent="center" onClick={handleLogout}>
                      <Flex cursor="pointer" d="flex" justifyContent="left" alignItems="center" w="10vw" p={3}>
                        <Image h="16px" w="16px" mr={2} src={logout} />
                        <Text whiteSpace="nowrap" color="#062F36" fontSize={16} fontWeight={400} textAlign="left">
                          Logout
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                  <Box>
                    <StatusIndicator />
                  </Box>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}

      {/* Web view */}
      {isDesktop && (
        <Box bgColor="#fff"  w={sidebarWidth !== null ? `${sidebarWidth}px`: `300px`} className="h-[100vh]"  boxShadow="10px 0px 20px rgba(0, 0, 0, 0.05)">
          <Box  mb={8} h={20} display="flex" alignItems="center" pl="10%" ml="10%" cursor="pointer" justifyContent='space-between'>
            <Image onClick={()=>navigate("/dashboard")} display={isHidden ? "none" : "block"} w="auto" h={32} src={brandLogo} alt="brandLogo" borderWidth="2px" borderColor="red" />
            <div onClick={toggleSidebar}>{isHidden ? <ChevronRightIcon w={8} h={8} /> : <ChevronLeftIcon w={8} h={8} />}</div>
          </Box>
          <Box w="100%" overflow="hidden" position="relative" h="90%">
          <Box display="flex" flexDir="column" justifyContent="flex-start" position="absolute" h="100%" overflow={`hidden`} width={'100%'} pb={20}>
            <Box>
              {navs?.map((nav, index) => (
                <Box onClick={() =>  {if(userInfo?.status == "disabled") return ;setActivePage(nav.to)}} key={nav.name} w="100%">
                  <NavLink to={userInfo?.status == "disabled" ? "#" : nav.to}  style={{ textDecoration: "none" }}>
                    <Box bgColor={activePage === nav.to && "rgba(19, 74, 112, 0.05)"} borderRadius={activePage === nav.to && "0px 30px 30px 0px"} borderLeft={activePage === nav.to ? "5px solid #134A70" : "5px solid transparent"} my={2} pl={"0.6rem"} d="flex" h="40px" justifyContent="center">
                    <Flex d="flex" justify="left" alignItems="center" w="20vw" ml={isHidden ? "0px" : "10%"} pt={3}>
                        {activePage === nav.to ? nav.activeIcon : nav.icon}
                        <Text fontSize={isDesktop ? 16 : 18} fontWeight={400} textAlign="left" color={activePage === nav.to ? "#134A70" : "#9FA7AD"} display={isHidden ? "none" : "block"}>
                          {nav.name}
                        </Text>
                      </Flex>
                    </Box>
                  </NavLink>
                </Box>
              ))}

              <Box pl={isHidden ? "5%" :"10%"} onClick={handleLogout} cursor="pointer" w="100%" my={2} mt={5} d="flex" h="45px">
                <Flex cursor="pointer" justify="left" alignItems="center" w="10vw" pl={"0.6rem"} borderLeft={"5px solid transparent"}>
                  <Image h="16px" w="16px" color={"#9FA7AD"} mr={2} src={logout} />
                  <Text whiteSpace="nowrap" color={"#9FA7AD"} fontSize={18} fontWeight={400} textAlign="left" display={isHidden ? "none" : "block"}>
                    Logout
                  </Text>
                </Flex>
              </Box>
            </Box>
            {
              !isHidden ? (
                <Box ml="15%">
                <StatusIndicator />
              </Box>
              ) : null
            } 
            
          </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
