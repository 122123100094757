import React, { useState } from "react";
import {
  Center,
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";

export default function OpenExternalLinkModal({ isOpen, onClose, link, id }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const {addToast} = CustomToast()

  const handleConfirm = () => {
    mutate(
      { key: `offers/${id}/click`, method: "get", data: {} },
      {
        onSuccess(res) {
          //addToast(res)
        },
      }
    );
    window.open(link, '_blank');
    onClose()
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            {/* <form onSubmit={(e)=>addSlot(e)}> */}
            <Center d="flex" flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-3" justify={"center"}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Warning
                  </Text>
                  <AiFillWarning style={{ color: "yellow", fontSize: "30px" }} />
                </Flex>
                <Text textAlign={"center"} fontWeight={600} fontSize={16} color="#000000">
                  You're leaving Seed a Founder. Would you like to continue?
                </Text>
                <Text fontSize={15} color="#000000">
                  Disclaimer: Seed A Founder is not responsible for the content, accuracy, or availability of the external site.
                </Text>
              </Stack>
              <Flex gap={10}>
                <BrandButton
                  // isLoading={isLoading}
                  mt={6}
                  text={`Yes`}
                  colorScheme="blue"
                  bg={"red"}
                  onClick={handleConfirm}
                />
                <BrandButton
                  // isLoading={isLoading}
                  mt={6}
                  text="No"
                  //   colorScheme="red"
                  onClick={onClose}
                />
              </Flex>
            </Center>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
