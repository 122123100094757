import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack, Flex } from "@chakra-ui/layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import BrandButton from "../brandButton/BrandButton";

const SingleExpert = ({ expert, isOpen, onToggle, setSelectedExpert }) => {

    const navigate = useNavigate()

    const bookCall = () => {
        setSelectedExpert(expert);
        onToggle();
    }

    const viewDetails = () => {
        navigate(`/view-user?userid=${expert?.id}&usertype=${expert?.userTypeId}`);
    }

    return (
        <Box w="275px" h="320px" borderRadius="10px" boxShadow="0px 1px 8px lightgray" py={2}>
            <VStack alignItems="flex-start" mx={4} >
                <div className="h-16 bg-gray-200 w-full mb-[-36px]"></div>
                <Avatar onClick={viewDetails} className="cursor-pointer" name={`${expert.first_name} ${expert.last_name}`} src={expert.profile_image} />
                <Box w="100%">
                    <Heading textOverflow="ellipsis" whiteSpace="nowrap" fontSize="16px">
                    {`${expert.first_name} ${expert.last_name}`}
                    </Heading>                    
                </Box>
                <Box maxW="100%" h="120px" overflow="hidden" px={0} py={2}>
                <Text textOverflow="ellipsis" fontSize="12px" whiteSpace="nowrap" color="gray">
                        Location: {expert.expert_price?.location?.title}
                    </Text>
                    <Text textOverflow="ellipsis" fontSize="12px" whiteSpace="nowrap" color="gray">
                        Profession: {expert.position}
                    </Text>
                    <Text textOverflow="ellipsis" fontSize="12px" whiteSpace="nowrap" color="gray">
                        Category: {expert.category}
                    </Text>
                    <div className="text-[12px] mt-2">
                        {expert.bio}
                    </div>
                </Box>
                <HStack spacing={2} alignItems="center" justifyContent="center" mt={2} className="mx-auto" mx={`auto`}>
                    <BrandButton text="Book A Call" width={120} colorScheme="blue" onClick={bookCall}/>
                </HStack>
            </VStack>
        </Box>
    )
}
export default SingleExpert;