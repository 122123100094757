import React, { useState, useEffect } from "react";
import { Box, Flex, Text, VStack, Avatar, Heading, Wrap, WrapItem } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import BrandButton from "../brandButton/BrandButton";
import client from "../../libs/client";
import StartupOffer from "./StartupOffer";
import { BASE_URL } from "../../helpers/constant";


const ViewOfferDetails = ({getDeal, offer, onToggle, setSelectedOffer, isOpen}) =>{
    const isDesktop = IsDesktop();

    return (
        <>
            <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
                <Box mr={2}><HiOutlineArrowNarrowLeft size="35px"/></Box>
                <Box><Text fontWeight={600}>Back to all deals</Text></Box>
            </Flex>
            <VStack className="px-1 lg:px-5 max-w-screen-lg" mt={6}  bg="white" borderRadius="10px" boxShadow="0.5px 2px 5px lightgray" justifyContent="space-between" alignItems="flex-start">
                <VStack alignItems="flex-start" p={10} justifyContent="space-between">
                        <Flex className="flex-col w-full">
                            <Avatar className="mx-auto self-center" name={offer.title} src={offer.picture} size={isDesktop ? "2xl" : "lg"}/>
                            <VStack mt={3} alignItems="flex-start">
                                <Heading fontSize={isDesktop ? "24px": "16px"}>
                                    {offer.title}
                                </Heading>
                                <Text fontSize={isDesktop ? "20px" : "14px"} color="brand.primary">Reference No: {offer.reference}</Text>
                                <Text fontSize={isDesktop ? "20px" : "14px"} color="brand.primary">
                                    Offer Price: {offer.price}
                                </Text>
                            </VStack>
                        </Flex>
                    <Box  overflowY="auto" maxH={"70vh"} className="py-10" w="100%">
                        <Text fontSize={isDesktop ? "16px" : "13px"}>
                            {offer.description}
                        </Text>
                    </Box>
                    <BrandButton text="Get Offer" onClick={()=> getDeal(offer)} width={isDesktop ? "300px": "100%"} colorScheme="blue"/>
                </VStack>
            </VStack>
        </>
    )
}
export default ViewOfferDetails;