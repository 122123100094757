import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack, Divider, Flex } from "@chakra-ui/layout";
import React from "react";
import ConnectionButton from "../connectionButton/ConnectionButton";
import { Image, Tooltip } from "@chakra-ui/react";
import default_cover_photo from "../../public/icons/default_cover_photo.png";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsFillHandThumbsUpFill, BsFillHandThumbsDownFill, BsXCircle } from "react-icons/bs";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";

const SingleFounder = ({ founder, onToggle, setSelectedFounder, onDislikeCompany = () => {}, onCompleteAction = () => {} }) => {
  const navigate = useNavigate()
  const viewDetails = () => {
    navigate(`/view-user?userid=${founder?.id}&usertype=${founder?.userTypeId}&cid=${founder?.company?.id}`) 
    onToggle();
  };
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const loggedInUserType = userDetails?.userTypeTitle || "";

    
  const likeCompany = async(e, companyId) => {
      try {
          e.stopPropagation()
          //let _founders = founders.filter(user => user.company.id !== companyId) 
          //updateState(_founders, false, false)
    
          if(userDetails?.likedCompanies?.includes(companyId)){
            return client()
            .delete(
              `${BASE_URL}remove-preference/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }else{
            return client()
            .put(
              `${BASE_URL}like-company/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: [...userDetails.likedCompanies, companyId],
                disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }
          
        } catch (e) {
          //console.log("the error====", e)
        }
    }
    
    const disLikeCompany = async(e, companyId) => {
        try {
          e.stopPropagation()
     
          if(userDetails?.disLikedCompanies?.includes(companyId)){
            return client()
            .delete(
              `${BASE_URL}remove-preference/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }else{
            return client()
            .put(
              `${BASE_URL}dislike-company/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId),
                disLikedCompanies: [...userDetails.disLikedCompanies, companyId],
              }))

              onDislikeCompany(companyId)

            });
          }
          
        } catch (e) {
          //console.log("the error====", e)
        }
    }

  return (
    <Box borderRadius="10px" className="w-[90vw] max-w-[330px] relative" boxShadow="0px 1px 8px lightgray">
      {
        loggedInUserType === "investor" ? (
            <Flex className="flex-row justify-end absolute right-2 z-20 my-2 w-full">
              <Tooltip label="remove from list">
                <span>
                  <BsXCircle className="cursor-pointer bg-white p-1 rounded-full" onClick={(e) => {disLikeCompany(e, founder?.company?.id)}} fill={userDetails?.disLikedCompanies?.includes(founder?.company?.id) ? 'red' : 'grey'} size={"26px"} />
                </span>
              </Tooltip>
            </Flex>
        ) : null
      }
      <Flex className="flex-col">
        <Box w="100%" h="120px" bg="lightgray" position="relative">
          {founder?.cover_photo && (
            <Box position="absolute" top="0" w="100%" h="120px" overflow="hidden">
              <Image
                src={founder?.cover_photo}
                //   src="https://images.unsplash.com/photo-1579353977828-2a4eab540b9a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2FtcGxlfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                objectFit="cover"
                position="absolute"
                top="0"
              />
            </Box>
          )}
          {!founder?.cover_photo && (
            <Box position="absolute" top="0" w="100%" h="120px" overflow="hidden">
            <Image
              src={default_cover_photo}
              objectFit="cover"
              position="absolute"
              top="0"
            />
          </Box>
          )}
        </Box>
        <Box cursor="pointer" className="px-3" onClick={viewDetails}>
          <Avatar size="lg" mt="-35px" name={founder?.company?.title} src={founder.profile_image} />
          <Box w="80%" >
            <Flex w="90%" justifyContent={"space-between"} overflow="hidden" className="space-between" >
              <Heading textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="14px">
                {`${founder.company.title}`}
              </Heading>
            </Flex>
            <Box w="90%" overflow="hidden">
              <Text textOverflow="ellipsis" fontSize="12px" whiteSpace="nowrap" overflow="hidden" color="gray">
                {founder.position}
              </Text>
            </Box>
          </Box>
          
        </Box>
        
        <Flex className="flex-col p-3 gap-2" w="100%" >
          <Flex className="flex-col gap-2 h-[150px] overflow-hidden" w="100%" >
          <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Founder:
              </Text>
              <Text color="#000">
              {`${founder.first_name} ${founder.last_name}`}
              </Text>
            </Flex>
            <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Location:
              </Text>
              <Text color="#000">
                  {founder.company?.incorporation_location?.map(({name}) => name).join(', ')}
              </Text>
            </Flex>
            <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Company stage:
              </Text>
              <Text color="#000">
                  {founder.company.company_stage?.map(({title}) => title ).join(', ')}
              </Text>
            </Flex>
            <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Kind of fund:
              </Text>
              <Text color="#000">
                  {founder.company.fund_category?.title}
              </Text>
            </Flex>
            <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Business model:
              </Text>
              <Text color="#000">
                  {founder.company.business_model?.map(({title}) => title ).join(', ')}
              </Text>
            </Flex>
            <Flex
              alignItems="start"
              className={`gap-2 text-xs`}

            >
              <Text className="whitespace-nowrap">
                  Category:
              </Text>
              <Text color="#000">
                  {founder.company.business_category?.map(({title}) => title ).join(', ')}
              </Text>
            </Flex>
          </Flex>
          <Box maxW="100%" py={1}>
              <Text className="whitespace-nowrap text-xs">
                  Description
              </Text>
            <Text className="preview_message" color="brand.primary" fontSize="12px" textOverflow="ellipsis">
              {founder.company.description}
            </Text>
          </Box>
          <Flex className="justify-center">
            <ConnectionButton onCompleteAction={onCompleteAction} user={founder}/>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default SingleFounder;
