import React from "react";
import {
  Text,
  Box,
  Flex,
  Heading,
  Input,
  VStack,
  InputGroup,
  InputLeftAddon,
  Link,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import envelope from "../../../public/icons/envelope.svg";
import logo_2 from "../../../public/icons/logo_.svg";
import email_sent from "../../../public/icons/email_sent.gif";
import { Link as Route, useNavigate } from "react-router-dom";
import SideComponent from "../SideComponent";
import IsDesktop from "../../../utils/IsDesktop";
import { forgotPasswordSchema } from "../../../validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ValidationError from "../../../validators/ValidationError";
import { CustomToast } from "../../../components/toast/CustomToast";
import { mutateFunction, useMutation } from "../../../libs/apis";
import BrandButton from "../../../components/brandButton/BrandButton";

const ForgotPasswordForm = () => {
  const isDesktop = IsDesktop();
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const navigate = useNavigate();
  const { isOpen: isopenCheckEmail, onToggle: onToggleCheckEmail } =
    useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const formSubmit = (data) => {
    mutate(
      { key: "forgot-password", method: "post", data },
      {
        onSuccess(res) {
          // showCheckEmail();
          onToggleCheckEmail();
          addToast({ message: res.message, type: "info" });
        },
      }
    );
  };
  const CheckEmail = () => {
    return (
      <>
        <Flex
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Image
            src="https://jekawin.s3.amazonaws.com/email+sent.gif"
            alt="image"
            h="250px"
            w="300px"
          />
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
          >
            <Heading fontSize="32px" color="brand.primary">
              Message Sent
            </Heading>
            <Text textAlign="center" color="gray">
              Check your inbox or spam folder for further instructions
            </Text>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <Box h="100vh" display="flex" justifyContent="center" alignItems="center">
      {isDesktop && (
        <Flex w="50%">
          <SideComponent />
        </Flex>
      )}
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        minW="50%"
      >
        {isopenCheckEmail ? (
          <CheckEmail />
        ) : (
          <Box display="flex" justifyContent="space-between" flexDir="column">
            {!isDesktop && (
              <Flex justifyContent="center" mb={20} mt={-50}>
                <Image src={logo_2} boxSize='100px' className="mt-20" objectFit='cover' />
              </Flex>
            )}
            <Flex flexDir="column" h="100%" alignItems="flex-start">
              <Flex flexDir="column" mb={4}>
                <Heading mb={4} color="brand.primary">
                  Forgot Password
                </Heading>
                <Box maxW={{ base: "300px", md: "400px" }}>
                  <Text>
                    Enter the email address you registered with and we will send
                    you a magic link to reset your password.
                  </Text>
                </Box>
              </Flex>
              <Flex alignItems="center">
                <form onSubmit={handleSubmit(formSubmit)}>
                  <VStack spacing="25px" alignItems="flex-start">
                    <Flex flexDir="column">
                      <Text fontSize="14px" mb={2}>
                        Your Email
                      </Text>
                      <InputGroup>
                        <Flex
                          borderWidth="1px"
                          borderColor="brand.primary"
                          borderRadius="5px"
                          alignItems="center"
                        >
                          <InputLeftAddon
                            borderWidth="0px"
                            bg=""
                            children={<Image src={envelope} size={30} />}
                          />
                          <Input
                            {...register("email")}
                            focusBorderColor="#"
                            borderWidth="0px"
                            variant="outline"
                            placeholder="yourname@example.com"
                            w={{ base: "250px", md: "350px" }}
                            h="50px"
                          />
                        </Flex>
                      </InputGroup>
                      <ValidationError message={errors.email?.message} />
                    </Flex>
                    <VStack w="100%" alignItems="flex-start">
                      <BrandButton
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        colorScheme="blue"
                        type="submit"
                        w={{ base: "300px", md: "400px" }}
                        text="Send magic link"
                      />
                      <Link as={Route} to="/login">
                        <Text color="#134A70">Back to Login</Text>
                      </Link>
                    </VStack>
                  </VStack>
                </form>
              </Flex>
            </Flex>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
export default ForgotPasswordForm;
