import { Box, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import DashboardStartupOffer from "./DashboardStartupOffer";
import getRandomInt from "../../../utils/getRandomInt";

const StartupOffersCard = () => {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const seeAll = () => {
    navigate("/startup-offers");
  };
  const { data, isLoading } = useData(
    `offers?status=ongoing&page_no=1&page_size=5`
  );
  const [firstRandomNumber, setFirstRandomNumber] = useState(getRandomInt(0, (data?.data?.adverts || []).length))
  const [secondRandomNumber, setSecondRandomNumber] = useState(0)


  useEffect(() => {
    let _secondRandomNumber = (() => {
      let _num = getRandomInt(0, (data?.data?.adverts || []).length)
      return _num !== firstRandomNumber ? _num : firstRandomNumber > 0 ? _num - 1 : _num + 1
    })()

    setSecondRandomNumber(_secondRandomNumber)
  }, [firstRandomNumber])

  

  

  return (
    <Box
      w="full"
      minW="300px"
      h={isDesktop ? "100%" : "600px"}
      borderRadius="10px"
      boxShadow="0px 1px 8px lightgray"
      p={4}
    >
      <Flex justifyContent="space-between" mb={4}>
        <Heading fontSize="18px" color="brand.primary">
          Startup Offers
        </Heading>
        <Text
          cursor="pointer"
          fontSize="12px"
          textDecor="underline"
          color="brand.primary"
          onClick={seeAll}
        >
          See all
        </Text>
      </Flex>

      {isLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          {data?.data?.offers.length > 0 ? (
            <VStack
              h={isDesktop ? "95%" : "500px"}
              spacing={5}
              pb={isDesktop ? 10 : 20}
              px={2}
              justifyContent="flex-start"
              w="100%"
            >
              {[data?.data?.offers[firstRandomNumber], data?.data?.offers[secondRandomNumber]].map((offer) => (
                <DashboardStartupOffer key={offer?.referenceNo} offer={offer} />
              ))}
            </VStack>
          ) : (
            <>
              No Offer yet!
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default StartupOffersCard;
