import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { useMutation, mutateFunction } from "../../libs/apis";
import BrandButton from "../brandButton/BrandButton"
import { CustomToast } from "../toast/CustomToast";
import { setChatInfo } from "../../redux-toolkit/reducers/chatSlice";


const ConnectionButton = ({user, size, onCompleteAction = () => {}}) => {
    const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
    const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
    const queryClient = useQueryClient();
    const { addToast } = CustomToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [a, setA] = useState('a')

    const connectWithUser = () => {
        mutate(
            { key: `users/${loggedInUserId}/connect/${user.id}`, method: "post", data: {} },
            {
              onSuccess(res) {
                addToast(res)
                onCompleteAction(user.id, "Connection Request Sent")
                user.connectionStatus = "Connection Request Sent";
              },
            }
          );

    }

    const acceptRequest = () => {
      mutate(
        { key: `users/${loggedInUserId}/connections/${user?.connection?.connection_id  || user?.connection?.id}`, method: "put" }, 
        {
          onSuccess(res) {
            addToast(res);
            onCompleteAction(user.id, "Connected")
            user.connectionStatus = "Connected";
            user.connection.accepted ? user.connection.accepted = true : user.accepted = true;
          },
        }
      );
    }

    const chatWithConnection = () => {
      dispatch(setChatInfo(user));
      navigate('../connections')
    }

    return (
        <>
            {(user?.connectionStatus === "Not Connected") && <BrandButton isLoading={mutateIsLoading} text="Connect"  w="120px" onClick={()=>connectWithUser()} {...size} />}
            {(user?.connectionStatus === "Connection Request Received") && <BrandButton text="Accept" bg="orange.500" colorScheme="blue" w="100px" onClick={()=>acceptRequest()} {...size} />}
            {(user?.connectionStatus === "Connection Request Sent") && <BrandButton text="Pending" isDisabled colorScheme="blue" w="100px" {...size} />}
            {(user?.connectionStatus === "Connected") && <BrandButton  text="Chat" colorScheme="blue" bg="green" w="100px" onClick={()=>chatWithConnection()} {...size} />}

        </>
    )
}
export default ConnectionButton;