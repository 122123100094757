import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack, Flex } from "@chakra-ui/layout";
import React from "react";
import BrandButton from "../brandButton/BrandButton";
import ConnectionButton from "../connectionButton/ConnectionButton";
import { useNavigate } from "react-router-dom";

const SingleInvestor = ({ investor, onToggle, setSelectedInvestor }) => {
    const navigate = useNavigate()
    const viewDetails = () => {
        onToggle();
        navigate(`/view-user?userid=${investor?.id}&usertype=${investor?.userTypeId}`) 
        window.scrollTo(0, 0);       
    }
    

    return (
        <Box className="w-full md:w-[90vw] max-w-[330px]" borderRadius="10px" boxShadow="0px 1px 8px lightgray" >
            <VStack alignItems="flex-start" >
                <div className="my-0 bg-gray-200 min-h-10 py-8 w-full mb-[-40px]"></div>
                <Box className="px-3 cursor-pointer" onClick={viewDetails}>
                    <Avatar size={"lg"} name={`${investor.first_name} ${investor.last_name}`} src={investor.profile_image}/>
                    <Box className="mt-1">
                        <Heading className="max-w-[25ch] overflow-hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize="16px" textAlign='center'>
                        {`${investor.first_name} ${investor.last_name}`}
                        </Heading>
                    </Box>
                </Box>
                <Flex className="flex-col p-3 gap-2" w="100%" >
                    <Flex className="flex-col gap-2 h-[250px] overflow-hidden" w="100%" >
                        <Flex
                            alignItems="start"
                            className={`gap-2 text-xs flex-col`}
                            
                        >
                            <Text className="whitespace-nowrap inline-block font-bold text-gray-700">
                                Location:
                            </Text>
                            <Text color="#000">
                                {investor.company?.incorporation_location?.map(({name}) => name).join(', ')}
                            </Text>
                        </Flex>
                        <Flex
                        alignItems="start"
                        className={`gap-2 text-xs flex-col`}

                        >
                        <Text className="whitespace-nowrap font-bold text-gray-700">
                            Company stage:
                        </Text>
                        <Text color="#000">
                            {investor.company.company_stage?.map(({title}) => title ).join(', ')}
                        </Text>
                        </Flex>
                        <Flex
                        alignItems="start"
                        className={`gap-2 text-xs flex-col`}

                        >
                        <Text className="whitespace-nowrap font-bold text-gray-700">
                            Kind of fund:
                        </Text>
                        <Text color="#000">
                            {investor.company.fund_category?.title}
                        </Text>
                        </Flex>
                        <Flex
                        alignItems="start"
                        className={`gap-2 text-xs flex-col`}

                        >
                        <Text className="whitespace-nowrap font-bold text-gray-700">
                            Business model:
                        </Text>
                        <Text color="#000">
                            {investor.company.business_model?.map(({title}) => title ).join(', ')}
                        </Text>
                        </Flex>
                        <Flex
                        alignItems="start"
                        className={`gap-2 text-xs flex-col`}

                        >
                        <Text className="whitespace-nowrap font-bold text-gray-700">
                            Category:
                        </Text>
                        <Text color="#000">
                            {investor.company.business_category?.map(({title}) => title ).join(', ')}
                        </Text>
                        </Flex>
                    </Flex>
                    <Box maxW="100%" py={1}>
                        <Text fontSize="12px" className="whitespace-nowrap font-bold text-gray-700">
                            Description:
                        </Text>
                        <Text className="preview_message" fontSize="12px" textOverflow="ellipsis">
                            {investor.company?.description}
                        </Text>
                    </Box>
                    <Flex className="justify-center">
                        <ConnectionButton user={investor}/>
                    </Flex>
                </Flex>
            </VStack>
        </Box>
    )
}
export default SingleInvestor;