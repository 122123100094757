import React, { useState } from "react";
import { Box, Flex, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import DashboardUserCard from "./DashboardUserCard";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import IsDesktop from "../../../../utils/IsDesktop";
import { useSelector, useDispatch } from "react-redux";

// ... (previous imports)

const ConnectCard = ({ userType, data = [], isLoading, onSelect, buttonSize = {}, onCompleteAction = () => {}, children, onScroll = () => {}, containerRef = null, selectedUser, videoUrl, goToDetailsPage = true, onDislikeCompany = () => {}}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const loggedInUserStatus =  useSelector((state) => state?.user?.userInfo?.status || "");

  const nextUser = () => {
    const totalPages = Math.ceil(data.length / 2);
    setPageNumber((prevPage) => (prevPage + 1) % totalPages);
  };

  const previousUser = () => {
    const totalPages = Math.ceil(data.length / 2);
    setPageNumber((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  const seeAll = () => {
    if(loggedInUserStatus === "disabled") return
    navigate(`/${userType.toLowerCase()}`);
  }; 

  return (
    <Box d="flex" flexDir="column" className={`h-full w-[90vw] overflow-hidden ${!isDesktop ? 'max-w-[450px]' : 'max-w-[350px]'}`}  alignItems="center"  minH="300px" borderRadius="10px" p={4}>
      <Box className="grid gap-4">
        <Flex justifyContent="space-between" className="flex-wrap items-center" mb={4}>
          <Heading fontSize="18px" color="brand.primary">
            Connect with {userType}
          </Heading>
          <Text cursor="pointer" fontSize="12px" textDecor="underline" color="brand.primary" onClick={seeAll}>
            See all
          </Text>
        </Flex>
        {children}
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <VStack justifyContent="space-between" className="py-2 px-0" h="100%" >
            <VStack className={`w-[100%] hide-scroll ${isDesktop ? 'pb-10' : ''}`} onScroll={onScroll} ref={containerRef} gap={2} overflowY={"scroll"}>
              {data.length > 0 ? data.map((user, index) => {
                return (
                  <Box className="w-[100%] px-1 rounded-md cursor-pointer shadow-xs border border-gray-100" onClick={() => onSelect(index)} key={index}>
                    <DashboardUserCard onCompleteAction={onCompleteAction} onDislikeCompany={onDislikeCompany} userType={(userType || "").toLowerCase()} goToDetailsPage={goToDetailsPage} buttonSize={buttonSize} user={user} selectedUser={selectedUser} videoUrl={videoUrl}/>
                  </Box>

                )
              }): (
                <>No {userType || ""} available yet!</>
              )}
            </VStack>
        </VStack>
      )}
    </Box>
  );
};

export default ConnectCard;
