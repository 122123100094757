import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import IsDesktop from "../../utils/IsDesktop";
import ViewExpertDetails from "../experts/ViewExpertDetails";
import ViewFounderDetails from "../Founders/ViewFounderDetails";
import ViewInvestorDetails from "../investors/ViewInvestorDetails";
import SearchBar from "../searchbar/SearchBar";

const ViewSingleUser = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({});
    const userid = searchParams.get("userid");
    const cid = searchParams.get("cid");
    const usertype = searchParams.get("usertype")
    const isDesktop = IsDesktop();

    const getUserType = (userTypeId) => {
        switch (userTypeId){
            case '1':
              return "Founder"
            case '2':
              return "Investor"
            case '3':
              return "Expert"
            default:
                break
          }
    }

    const goBack = () => {
      window.scrollTo(0, 0);
      navigate(-1)
    }


    return (
      <Flex className="w-[100%] flex-col overflow-hidden p-3">

            <Flex justifyContent="space-between">

              {isDesktop && (
                  <Box minW="100px">
                    <Heading whiteSpace="nowrap" color="brand.primary" fontSize={isDesktop ? "20px" : "14px"}>
                    View {getUserType(usertype)}
                    </Heading>
                  </Box>)
              } 
            </Flex>
            {usertype === "1" && <ViewFounderDetails founder={userid} companyId={cid || null} onToggle={goBack}/>}
            {usertype === "2" && <ViewInvestorDetails investor={userid} onToggle={goBack}/>}
            {usertype === "3" && <ViewExpertDetails expert={userid} onToggle={goBack}/>}

        </Flex>
    )
}
export default ViewSingleUser;