import { 
    Table, 
    Avatar, 
    Box, 
    Flex, 
    Tbody, 
    Td, 
    Text,
    Th, 
    Thead, 
    Tr, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Textarea,
    Stack,
    useDisclosure
} from '@chakra-ui/react';
import moment from 'moment';
import BrandButton from '../brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import IsDesktop from '../../utils/IsDesktop';
import Spinner from '../spinner/Spinner';
import { FaRegCheckCircle,  FaRegTimesCircle, FaTrash } from "react-icons/fa";
import { mutateFunction, useMutation } from '../../libs/apis';
import { CustomToast } from '../toast/CustomToast';
import { useState } from 'react';
import { useSelector } from 'react-redux';


const FoundersBookingList = ({ listType = "upcoming", header = [], data = [], isLoading, refetch }) => {
    const {mutate, isLoading : mutateIsLoading} = useMutation(mutateFunction)
    const {mutate: rejectMutate, isLoading : rejectMutateIsLoading} = useMutation(mutateFunction)
    const isDesktop = IsDesktop();
    const navigate = useNavigate()
    const { addToast }= CustomToast()
    const [reason, setReason] = useState(null)
    const {isOpen, onClose, onOpen} = useDisclosure()
    
    const deleteBooking  = (id) => {
        mutate(
            { key: `users/founders/remove-booking/${id}`, method: "delete", data: {} },
            {
              onSuccess(res) {
                addToast({message: 'Booking Removed'})
                refetch()
              },
            }
        );
    }

    return (
        <Box mt={6} overflowY='auto' borderRadius='10px 10px 10px 10px'>
            <Table whiteSpace='nowrap'>
                {isDesktop && <Thead>
                    <Tr
                        bg='rgba(19, 74, 112, 0.05)'
                        borderRadius='10px 10px 0px 0px'
                        boxShadow='0px 0px 8px 4px rgba(19, 74, 112, 0.1)'
                        borderBottom='1px solid #9FA7AD'
                    >
                        {header?.map(column => (
                            <Th key={column.key} color='#000' fontSize={{base: 12, '2xl': 16}}>{column.title}</Th>
                        ))}
                        <Th>{" "}</Th>
                    </Tr>
                </Thead>}

                <Tbody borderRadius='10px' h="100%" overflowY='auto' p={2}>
                        {(data.length === 0 && isLoading) && <Spinner mt={2}/>}

                        {data.sort((a, b)=>
                            moment(new Date(a.slot_start_date)).isBefore(moment(new Date(b.slot_start_date))) ? 1 : -1).map((booking) => {
                                return(
                            <Tr
                                key={booking.id}
                                boxShadow='10px 10px 20px rgba(54, 171, 190, 0.05)'
                                borderRadius='10px'
                                bg='white.300'
                                maxH="60px"
                                p={2}
                            >
                                <Td>
                                    <Flex alignItems='center'>
                                        <Avatar className='cursor-pointer' onClick={() => {
                                            navigate(`/view-user?userid=${booking?.expert_id}&usertype=3`)
                                        }} size='xs' src={booking?.expert_slot?.expert?.profile_image || ''} name={booking?.expert_slot?.expert?.first_name} mr={2} />
                                        <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{`${booking?.expert_slot?.expert?.first_name} ${booking?.expert_slot?.expert?.last_name}`}</Text>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.expert_slot?.slot_start_date).format('DD/MM/YYYY')}</Text>
                                </Td>
                                {isDesktop && <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.expert_slot?.slot_start_time, "hh:mm:ss").format("hh:mm")}</Text>
                                </Td>}
                                {isDesktop && <Td>
                                    <Text fontSize={{ base: 12, '2xl': 16 }} fontWeight={500} textTransform='capitalize'>{moment(booking?.expert_slot?.slot_end_time, "hh:mm:ss").format("hh:mm")}</Text>
                                </Td>}
                                <Td cursor='pointer'>
                                    <Flex className='gap-3 items-center justify-center'>
                                    <FaTrash onClick={() => {deleteBooking(booking.id)}} color='red' />
                                    </Flex>
                                </Td>
                            </Tr>
                    )})}
                </Tbody>
            </Table>
            {(data.length === 0 && !isLoading)? (
                <Text onClick={() => {navigate('/dashboard?slot=open')}} className='text-center w-full px-2 pt-10 text-gray-700 font-medium hover:font-semibold hover:underline cursor-pointer'>
                    You currently do not have any bookings! Click to add slot
                </Text>
            ) : null}
        </Box>
    );
}
 
export default FoundersBookingList;