import { Avatar, Box, Flex, HStack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { Divider } from "antd";
import React, { useState } from "react";
import BrandButton from "../../../components/brandButton/BrandButton";
import OpenExternalLinkModal from "../../../components/modal/OpenExternalLinkModal";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useMutation, mutateFunction } from "../../../libs/apis";

const DashboardStartupOffer = ({offer}) => {
    const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const {
        isOpen: modalIsOpen,
        onClose: onCloseModal,
        onOpen: onOpenModal
    } = useDisclosure()

    const [link, setLink] = useState('')
    const [offerId, setOfferId] = useState('')

    const getDeal = (offer) => {
        setOfferId(offer.id)
              if (offer.offer_url !== null) {
                if (
                  offer.offer_url.startsWith("http://") ||
                  offer.offer_url.startsWith("https://")
                ) {
                  //window.open(offer.offer_url);
                  setLink(offer.offer_url)       
                  onOpenModal()
                } else {
                  //window.open("https://" + offer.offer_url);
                  setLink(`https://${offer.offer_url}`)
                  onOpenModal()
                }
              } else {
                addToast({ message: "No link", type: "warning" });
              }
      };
    return (
            <Box w="100%" h="250px">
            <VStack alignItems="flex-start">
                <OpenExternalLinkModal 
                isOpen={modalIsOpen} id={offerId} onClose={() => {
                    onCloseModal()
                    setLink('')
                    setOfferId('')
                  }} link={link} 
                />
                <Flex alignItems="center">
                    <Avatar name={offer?.title} src={offer?.brand?.logo}/>
                    <Text ml={2} fontSize="16px" fontWeight={700}>
                        {offer?.title}
                    </Text>
                </Flex>

                <Box maxW="100%" maxH="40px" overflow="hidden" mb={2}>
                    <Text fontSize="12px" textOverflow="ellipsis">
                        {offer?.description}
                    </Text>
                </Box>
                <Text color="brand.primary">
                   $ {offer?.price}
                </Text>
                <HStack spacing={2} alignItems="center" justifyContent="flex-start" mt={2}>
                    <BrandButton text="Get Deal" isLoading={mutateIsLoading} width="100px" onClick={()=> getDeal(offer)} />
                </HStack>
                <Divider/>
            </VStack>
        </Box>
    )
}
export default DashboardStartupOffer;