import { Box, Flex, Spinner, Text, VStack, Avatar, useDisclosure} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { Select } from "antd";
import { GoSettings } from "react-icons/go";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import IsDesktop from "../../utils/IsDesktop";

const SearchBar = ({
    styles = {},
    className = "",
    avatarSize = "sm",
    userType = "all",
    searchType = "user",
    searchRoute = "",
    status = null,
    onSelectOption = () => {}
}) => {
    const {mutate, isLoading} = useMutation(mutateFunction)
    const loggedInUserStatus = useSelector((state) => state?.user?.userInfo?.status || "");
    const [input, setInput] = useState("")
    const [searchData, setSearchData] = useState([])
    const { OptGroup } = Select;
    const {isOpen: userIsOpen, onToggle: onToggleUser} = useDisclosure();

    const navigate = useNavigate();

    const search = useCallback((inputValue) => { 
        if(inputValue !== "" && loggedInUserStatus !== "disabled"){
            mutate(
                {key: searchType !== "user" && searchRoute ? `${searchRoute}?q=${inputValue}` : `search-users?q=${inputValue}&type=${userType}${status ? `&status=${status}` : ''}`, method: "get"}, 
                {
                    onSuccess(res){
                        setSearchData(res.data.users)
                }})
        }
    },[mutate, userType, status])
    
    const goToUser = (userId) => {
        const user = searchData?.find((user) => user.id.toString() === userId)
        navigate(`../view-user?userid=${userId}&usertype=${user?.userTypeId}`)
        // setSelectedUser(searchData?.find((user) => user.id.toString() === userId))
        
    }

    const goToOffer = (id) => {
        const offer = searchData?.find((offer) => offer.id.toString() === id)
        onSelectOption(offer)
    }

    const goToDeletedUser = (id) => {
        const deletedUser = searchData?.find((offer) => offer.id.toString() === id)
        onSelectOption(deletedUser)
    }

    const onSelectOptions = {
        "user" : goToUser,
        "offer" : goToOffer,
        "deleted-user": goToDeletedUser
    }

    const tabs  = {
        "user" : (item) => (
            <Flex alignItems="center">
                <Avatar size={avatarSize} name={`${item.first_name} ${item.last_name}`} src={item.profile_image}/>
                <Text ml={4}>{`${item.first_name} ${item.last_name}`}</Text>
            </Flex>
        ),
        "offer" : (item) => (
            <Flex alignItems="center">
                <Avatar size={avatarSize} name={`${item.title}`} src={''}/>
                <Text ml={4}>{`${item.title}`}</Text>
            </Flex>
        ),
        "deleted-user" : (item) => (
            <Flex alignItems="center">
                <Avatar size={avatarSize} name={`${item.firstName} ${item.lastName}`} />
                <Text ml={4}>{`${item.firstName} ${item.lastName}`}</Text>
            </Flex>
        ),
    }
    
    const searchResults = searchData?.map((item) => <Select.Option key={item.id}>
            { tabs[searchType] ? tabs[searchType](item) : null}
        </Select.Option>
    );


    return (
        <>
            <VStack>
                <Flex>
                    <Box mx={2}>
                        <Select key="globalSelect"
                            size="large"
                            showSearch
                            labelInValue={false}
                            autoClearSearchValue
                            // autoFocus
                            allowClear
                            // value={input}
                            listHeight={500}
                            placeholder="Search"
                            style={{...styles}}
                            className={`w-full min-w-[230px] max-w-[400px] max-h-[500px] rounded-sm ${className}`}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={search}
                            onChange={setInput}
                            notFoundContent={isLoading ? <Spinner/> : "No data to show"}
                            onSelect={ onSelectOptions[searchType] ?  onSelectOptions[searchType] : onSelectOptions["user"] }
                            //   onBlur={onblur}
                            >
                            {!isLoading && (
                            <>
                                <OptGroup key="Users" label="Users">{searchResults.length > 0 ? searchResults : <Select.Option key="searchDatanull" fontSize="3px">No match found</Select.Option>}</OptGroup>
                            </>)}
                        </Select>
                    </Box>
                    {/* <Box bg="brand.primary" p={2} borderRadius="5px">
                        <GoSettings size="20px" color="white" />
                    </Box> */}
                </Flex>
            </VStack>
        </>
    )
}
export default SearchBar; 