import { Box, Table, Tbody, Tr, Td, TableContainer, Avatar, Text, Flex, Button, Spinner, VStack, HStack, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useData } from "../../../data";
import moment from "moment";
import SearchBar from "../../../components/searchbar/SearchBar";
import Header from "../../../components/header/Header";
import OffersCountSection from "./OffersCountSection";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiPause, FiPlay } from "react-icons/fi";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import ViewOfferDetails from "./ViewOfferDetails";
import BrandButton from "../../../components/brandButton/BrandButton";
import AddOffer from "./AddOffer";
import IsDesktop from "../../../utils/IsDesktop";

const StartupOffers = () => {
  const { data, isLoading, refetch } = useData("offers?status=&page_no=1&page_size=20");
  const [displayedListStatus, setDisplayedListStatus] = useState("all");
  const [displayedList, setDisplayedList] = useState([]);
  const {isOpen: offerDetailsIsOpen, onToggle: onToggleOfferDetails} = useDisclosure();
  const {isOpen: addOfferIsOpen, onToggle: onToggleAddOffer} = useDisclosure();
  const [selectedOffer, setSelectedOffer] = useState(0)
  const changeUserView = (offerStatus) => {
    setDisplayedListStatus(offerStatus);
  };
  const { mutate } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const isDesktop = IsDesktop()

  const addNewOffer = () => {
    onToggleAddOffer();
  }


  const sortList = (offerActiveStatus) => {
    if (offerActiveStatus === "all") {
      return data?.data?.offers;
    }
    return data?.data?.offers?.filter((offer) => offer.is_active === offerActiveStatus);
  };
  const changeStatus = (offerId) => {
    mutate(
        { key: `offers/${offerId}`, method: "patch" },
        {
          onSuccess(res) {
            addToast(res);
            refetch()
  
          },
        }
      );
  }
  const deleteOffer = (offerId) => {
    mutate(
      { key: `offerDelete/${offerId}`, method: "delete" },
      {
        onSuccess(res) {
          addToast(res);
          refetch()

        },
      }
    );
  }
  const viewOffer = (offer) => {
    setSelectedOffer(offer);
    onToggleOfferDetails();
  }

  useEffect(() => {
    if (data?.data) {
      setDisplayedList(sortList(displayedListStatus));
    }
  }, [data?.data, displayedListStatus]);
  return (
    <Box>
      <Header title="Startup Offers" />
      <Flex className={`flex-col px-3 py-5 ${isDesktop ? 'max-h-[95vh] pb-20 overflow-y-scroll' : ''}`}>
      {!isLoading ? (
        <>
        {offerDetailsIsOpen && (<ViewOfferDetails refetch={refetch} brand={selectedOffer?.brand} offerId={selectedOffer?.id} onToggle={onToggleOfferDetails} changeStatus={changeStatus} deleteOffer={deleteOffer} />)}
        {addOfferIsOpen && (<AddOffer onToggle={onToggleAddOffer} refetch={refetch} />)}
        {(!offerDetailsIsOpen && !addOfferIsOpen) && (
          <>
          <Flex w="100%">
            <Box mt="20px" w="100%">
              <HStack w="50%" justifyContent="space-between">
              <OffersCountSection totalCount={data?.data?.totalOffers} totalGotten={data?.data?.totalGotten} />
              <BrandButton text="Add New" w="120px" onClick={addNewOffer} />
              </HStack>
              <Flex w="100%" justify="space-between" alignItems="center">
                <SearchBar />
                <Flex alignItems="flex-start" pr={2} h="29px" w="67">
                  <Button bg={displayedListStatus === "all" ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === "all" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} borderRadius="5px 0px 0px 5px" boxShadow="base" onClick={() => changeUserView("all")}>
                    All
                  </Button>
                  <Button bg={displayedListStatus === true ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === true ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px" onClick={() => changeUserView(true)}>
                    Ongoing
                  </Button>
                  <Button bg={displayedListStatus === false ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === false ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserView(false)}>
                    Paused
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          {/* --------------------table */}
          <TableContainer mt="50px" borderRadius="10px">
            <Table variant="simple" size="sm" fontSize="12px">
              <Tbody>
                {displayedList?.map((items) => {
                  return (
                    <Tr
                      key={items.id}
                      style={{ padding: "2rem 2rem", height: "80px" }}
                      boxShadow="md"
                      borderRadius="10px"
                      _hover={{
                        borderWidth: "2px",
                        borderColor: "brand.primary",
                        borderRadius: "10px",
                      }}
                    >
                      <Td cursor="pointer"
                      onClick={() => viewOffer(items)}>
                        <VStack justifyContent="center" alignItems="flex-start">
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.title || "N/A"}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Added on {moment(items?.createdAt).format(`MMM D, YYYY`)}
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <Flex alignItems="center">
                          <Avatar name={`${items?.brand?.name}`} src={items?.brand?.logo} alt="" size="sm" mr={2} />
                          <Text>{`${items?.brand?.name}`}</Text>
                        </Flex>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.price}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Offer Value
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {moment(items?.start_date).format(`DD/MM/YYYY`)}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Start date
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {moment(items?.end_date).format(`DD/MM/YYYY`)}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            End date
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.gotten || 0}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Gotten
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <HStack>
                            {items?.is_active === true && (
                            <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#E6F3E6" >
                                <Text color="green" fontWeight={500}>Ongoing</Text>
                            </Box>
                            )}
                            {items?.is_active === false && (
                            <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#FFF6E6" >
                                <Text color="orange" fontWeight={500}>Paused</Text>
                            </Box>
                            )}
                            {items?.is_active === true && (<Box cursor="pointer" onClick={()=> changeStatus(items.id)} border="1px" borderColor="orange" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><FiPause color="orange"/></Box>)}
                            {items?.is_active === false && (<Box cursor="pointer" onClick={()=> changeStatus(items.id)} border="1px" borderColor="green" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><FiPlay color="green"/></Box>)}
                            <Box cursor="pointer" onClick={()=> deleteOffer(items.id)} border="1px" borderColor="red" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><RiDeleteBin6Line color="red"/></Box>
                        </HStack>
                        </Td>
                      <Td>
                        <BiDotsVerticalRounded />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          </>
        )}
        </>
      ) : (
        <>
          <Spinner m={5} />
        </>
      )}
      {/* --------------------table */}
      </Flex>
    </Box>
  );
};

export default StartupOffers;
