import { Avatar, Box, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewBookingModal from "./ViewBookingModal";


function Booking({ booking, selectBooking}) {
    return (
      <Box cursor="pointer" onClick={()=> selectBooking(booking)} bg="brand.primary" p={2} borderRadius={4} mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Avatar name={`${booking?.first_name} ${booking?.last_name}`} src={booking?.profile_image} />
        <Box ml={4}>
          <Text fontSize="14px" color="white" mb={1} fontWeight={500}>
          {`${booking?.first_name} ${booking?.last_name}`}
          </Text>
        <Text fontSize="sm" color="white">
          {booking?.slot_start_time} - {booking?.slot_end_time}
        </Text>
        </Box>
        </Box>
        <BsThreeDotsVertical color="white"/>
      </Box>
    );
  }


const TimeSlots = ({bookings, loading}) => {
  const [selectedBooking, setSelectedBooking] = useState();
  const {isOpen: isViewBookingModalOpen, onOpen: onOpenViewBookingModal, onClose: onCloseViewBookingModal} = useDisclosure();
  const selectBooking = (booking) => {
    setSelectedBooking(booking);
    onOpenViewBookingModal();
  }
  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }

    return (
        <>
        {isViewBookingModalOpen && <ViewBookingModal booking={selectedBooking} isOpen={isViewBookingModalOpen} onClose={onCloseViewBookingModal} />}
        {(!loading && bookings.length > 0) && <Box position="relative" width="100%" height="auto">
          {[...Array(24)].map((_, eachHour) => {
            const startTime = new Date();
            startTime.setHours(eachHour);
            startTime.setMinutes(0);
            startTime.setSeconds(0);
    
            const endTime = new Date(startTime.getTime());
            endTime.setHours(eachHour + 1);
    
            const slotBookings = bookings?.filter((booking) => {
              return (
                moment(convertToTime(booking?.slot_start_time)).isBetween(moment(startTime), moment(endTime), null, "[)") 
              );
            });

            return (
              <>
              {slotBookings.length > 0 && <Box
                key={eachHour}
                m
                minH="60px"
                width="100%"
                border=""
                boxShadow="0px 0px 2px lightgray"
              >
                <Text ml={2} mt={2}>
                  {`${eachHour.toString().padStart(2, "0")}:00`}
                </Text>
                <Box px={4} h="auto">
                  {slotBookings
                    ?.sort((a, b) => a.startTime - b.startTime)
                    ?.map((booking) => (
                      <Booking
                        key={booking?.booking_id}
                        booking={booking}
                        selectBooking={selectBooking}
                      />
                    ))}
                </Box>
              </Box>}
              </>
            );
          })}
        </Box>}
        {loading && <Box width="100%" display="flex" justifyContent="center"><Text mr={2}>Fetching... </Text><Spinner /></Box>}
        {(!loading && bookings.length === 0) && <Box width="100%" display="flex" justifyContent="center"><Text>No bookings on this date...</Text></Box>}
        </>
      );
  }
export default TimeSlots;