import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import BrandButton from "../brandButton/BrandButton";

const StartupOffer = ({ offer, isOpen, getDeal, setIsOpen, onToggle, setSelectedOffer }) => {
    const viewDetails = () => {
        setSelectedOffer(offer);
        if(!isOpen) setIsOpen(true)
    }
    

    return (
        <Box w="275px" borderRadius="10px" boxShadow="0px 1px 8px lightgray">
            <VStack alignItems="flex-start" m={4}>
                <Avatar className="mx-auto" name={offer?.title} src={offer?.brand?.logo}/>
                <Box w="95%">
                <Text className="text-center" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="16px" fontWeight={700}>
                    {offer?.title}
                </Text>
                </Box>
                <Text color="brand.primary">
                    {`Offer price: ${offer?.price}`}
                </Text>
                <Box maxW="100%" mb={2}>
                    <Text className="preview_message" fontSize="15px" textOverflow="ellipsis">
                        {offer?.description}
                    </Text>
                </Box>
                <HStack spacing={2} alignItems="center" justifyContent="center" mt={2}>
                    <BrandButton text="Get Deal" width="100px" colorScheme="blue" onClick={()=> getDeal(offer)} />
                    <BrandButton text="View Details" width="130px" colorScheme="white" onClick={viewDetails}/>
                </HStack>
            </VStack>
        </Box>
    )
}
export default StartupOffer;