import { Image } from "@chakra-ui/react";
import dashboard from "../../../public/icons/dashboard.svg"
import dashboardActive from "../../../public/icons/dashboardActive.svg"
import connections from "../../../public/icons/connections.svg"
import connectionsActive from "../../../public/icons/connectionsActive.svg"
import investors from "../../../public/icons/investors.svg"
import investorsActive from "../../../public/icons/investorsActive.svg"
import startup from "../../../public/icons/startup.svg"
import startupActive from "../../../public/icons/startupActive.svg"
import experts from "../../../public/icons/experts.svg"
import expertsActive from "../../../public/icons/expertsActive.svg"
import settings from "../../../public/icons/settings.svg"
import settingsActive from "../../../public/icons/settingsActive.svg"
import { HUB_8020_AUTH_USER } from "../../../helpers/constant"
import users from "../../../public/icons/users.svg"
import creditCard from "../../../public/icons/creditCard.svg"
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { GrNotes } from "react-icons/gr";
import { BsPencilSquare } from "react-icons/bs";



const Navs = () => {
    const user = JSON.parse(localStorage.getItem(HUB_8020_AUTH_USER));
    const accountType = user?.userTypeTitle
    const founderNav = useMemo(()=>{
        return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='16px' w='16px' mr={2} src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='16px' w='16px' mr={2} src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/investors',
            name: 'Investors',
            icon: <Image h='16px' w='16px' mr={2} src={investors} alt='investors-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={investorsActive} alt='investors-icon'/>
        },
        {
            to: '/startup-offers',
            name: 'Startup Offers',
            icon: <Image h='16px' w='16px' mr={2} src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={startupActive} alt='startup-icon'/>
        },
        {
            to: '/bookings',
            name: 'My Bookings',
            icon: <BsPencilSquare style={{fontSize: "20px"}} className="mr-2 text-xl text-gray-500"/>,
            activeIcon: <BsPencilSquare style={{fontSize: "20px"}} className="mr-2 text-blue-[#134A70]" />
        },
        // {
        //     to: '/brands',
        //     name: 'Brands',
        //     icon: <Image h='16px' w='16px' mr={2} src={startup} alt='startup-icon'/>,
        //     activeIcon: <Image h='16px' w='16px' mr={2} src={startupActive} alt='startup-icon'/>
        // },
        {
            to: '/experts',
            name: 'Experts',
            icon: <Image h='16px' w='16px' mr={2} src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Account Settings',
            icon: <Image h='16px' w='16px' mr={2} src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={settingsActive} alt='settings-icon'/>
        }
    ]
    }, [])

    const expertNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='16px' w='16px' mr={2} src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/founders',
            name: 'Founders',
            icon: <Image h='16px' w='16px' mr={2} src={investors} alt='founder-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={investorsActive} alt='founder-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='16px' w='16px' mr={2} src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Account Settings',
            icon: <Image h='16px' w='16px' mr={2} src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])

    const adminNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='16px' w='16px' mr={2} src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/users',
            name: 'Users',
            icon: <Image h='16px' w='16px' mr={2} src={users} alt='all-users-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={dashboardActive} alt='all-users-icon'/> 
        },
        {
            to: '/startup-offers',
            name: 'Startup Offers',
            icon: <Image h='16px' w='16px' mr={2} src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={startupActive} alt='startup-icon'/> 
        },
        {
            to: '/brands',
            name: 'Brands',
            icon: <Image h='16px' w='16px' mr={2} src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={startupActive} alt='startup-icon'/>
        },
        {
            to: '/admin/subscriptions',
            name: 'Subscriptions',
            icon: <Image h='16px' w='16px' mr={2} src={creditCard} alt='connections-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={creditCard} alt='connections-icon'/>
        },
        {
            to: '/admin/adverts',
            name: 'Adverts',
            icon: <Image h='16px' w='16px' mr={2} src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/admin/deleted-users',
            name: 'Deleted Users',
            icon: <Image h='16px' w='16px' mr={2} src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Account Settings',
            icon: <Image h='16px' w='16px' mr={2} src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])

    const investorNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='16px' w='16px' mr={2} src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='16px' w='16px' mr={2} src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/founders',
            name: 'Founders',
            icon: <Image h='16px' w='16px' mr={2} src={investors} alt='founder-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={investorsActive} alt='founder-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Account Settings',
            icon: <Image h='16px' w='16px' mr={2} src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='16px' w='16px' mr={2} src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])
    const [navs, setNavs] = useState(founderNav)


    useEffect(()=>{
        switch (accountType) {
            case "admin":
                setNavs(adminNav);
                break;
            case "expert":
                setNavs(expertNav);
                break
            case "investor":
                setNavs(investorNav);
                break;
            default:
                setNavs(founderNav);
        }

    },[accountType, adminNav, expertNav, investorNav, founderNav])
    
    return navs;
}
export default Navs;