import { Box, Flex } from "@chakra-ui/react";
import ExpertsPage from "../../../components/experts/ExpertsPage";
import Header from "../../../components/header/Header";
import IsDesktop from "../../../utils/IsDesktop";

const Experts = () => {
    const isDesktop = IsDesktop()
    return (
        <Box>
            <Header title='Experts' />
            <Flex className={`flex-col ${isDesktop ? 'max-h-[95vh] pb-10 overflow-y-scroll' : ''}`}>
                <ExpertsPage/>
            </Flex>
        </Box>
    );
}
 
export default Experts;